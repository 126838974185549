import {IProduct} from "../models/products";


export function NavigationBySub(slug: any, slugId: any, subCategoryName: any){
  return `/products-list/${slug}/${slugId}/${subCategoryName}`
}

export function NavigateToDetail(product: IProduct){
  const categoryName = product.category.name.split(' ').join('-')
  const subCategoryName = product.sub_category?.name.split(' ').join('-')
  const productName = product.name.split(' ').join('-')
  return `/product-/${categoryName}/${product.category.id}/${subCategoryName}/${product.id}/${productName}`
}
