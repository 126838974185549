
type StatusType  = "success" | "error" | "warning" | "info" | "loading"

export const ErrorToast = "Не удалось добавить из за неизвестной ошибки"
export const SuccessToast = "Товар добавлен в вашу корзину"

export const SuccessChangeToast = "Товар изменен"
export const ErrorChangeToast = "Не удалось изменить товар"

export const SuccessPostDataToast = "Данные успешно отправлены"
export const ErrorPostDataToast = "Данные не отпралены! Возникла ошибка"

export const SuccessDeletedToast = "товар успешно удален"
export const ErrorDeletedToast = "Возникла ошибка"

export const InputErrorToast = "Пожалуйста заполните все обязательные поля"

export const TextCopiedToast = "Реквизит скопирован"


type ToastTypes =
  typeof ErrorToast |
  typeof SuccessToast |
  typeof SuccessChangeToast |
  typeof ErrorChangeToast |
  typeof SuccessPostDataToast |
  typeof ErrorPostDataToast |
  typeof SuccessDeletedToast |
  typeof ErrorDeletedToast |
  typeof InputErrorToast |
  typeof TextCopiedToast

interface IToastFn {
  title: string,
  status: StatusType,
  isClosable: boolean,
  position: 'top-right',
}

export const creatorToast = (title: ToastTypes, status: StatusType): IToastFn => {
  return {
    title: title,
    status: status,
    isClosable: true,
    position: 'top-right',
  }
}