import {AppDispatch} from "../../../store/store";
import {productsSlice} from "./productsSLice";
import $api from "../../../components/api/Api";
import {RootProducts} from "../../../models/products";
import {SortingTypes} from "../../../components/SimpleSorting/sort";
import {subProductsSlice} from "./sub-products.slice";


export const getALlProducts = (page: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(productsSlice.actions.getProducts())
    const response = await $api.get<RootProducts>(`products/?page=${page}`)
    dispatch(productsSlice.actions.getProductsSuccess(response.data))
  }catch (e: any) {
    console.log(e)
    dispatch(productsSlice.actions.getProductsError(e.response?.data?.message  || e.detail))
  }
}



export const getProductsBySlug = (category_id: number, sub_category: string = "Все", page: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(subProductsSlice.actions.getProductsBySlug())
    const subName = sub_category === "Все" ? `/?page=${page}` : `/?sub_categories=${sub_category}`
    // const pageQuery = `${!!subName ? '&' : '/?'}page=${page}`
    const response = await $api.get<RootProducts>(`categories/${category_id}/products${subName}`)
    dispatch(subProductsSlice.actions.getProductsBySlugSuccess(response.data))
  }catch (e: any) {
    console.log(e)
    dispatch(subProductsSlice.actions.getProductsBySlugError(e.response?.data?.message || e.detail))
  }
}



export const getSortingProducts = (sort: SortingTypes, navigate: any, forAllProduct: boolean = true) => async (dispatch: AppDispatch) => {
  try {
    if (forAllProduct){
      navigate('/products')
      dispatch(productsSlice.actions.getProducts())
      dispatch(productsSlice.actions.soringAllProducts(sort))
    }else {
      dispatch(subProductsSlice.actions.getProductsBySlug())
      dispatch(subProductsSlice.actions.soringSlugProducts(sort))
    }
  }catch (e: any) {
    console.log(e)
  }
}

export const getProductsPage = (page: number) => async (dispatch: AppDispatch) => {
  dispatch(productsSlice.actions.getCurrentPage(page))
}

export const getSubProductsPage = (page: number) => async (dispatch: AppDispatch) => {
  dispatch(subProductsSlice.actions.getCurrentPage(page))
}