import React, {useEffect, useState} from 'react';
import {Flex, Box, Text, Center, Image} from "@chakra-ui/react"
import Fancybox from "./ModalGallery/Fancybox";
import Description from "./Description/Description"
import {HiOutlineArrowSmLeft, HiOutlineArrowSmRight} from "react-icons/hi"
import TitleComponent from "../../components/TitleComponent";
// SWIPER
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import "swiper/css";
import "./style.scss"
import {Link, useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getDetail, getSimilarProducts, resetDetail} from "./DetailReducer/detailActions";
import ToCartButton from "./ToCartButton";
import DetailSkeleton from "./DetailSkeleton";
import {BsDot} from "react-icons/bs";
import {NavigateToDetail, NavigationBySub} from "../../helpers/path";
import TextErrorrr from "../../components/ui/TextErrorrr";

const DetailPage = () => {
  const dispatch = useAppDispatch()
  const {detail, isError, isLoading, similarProducts} = useAppSelector(state => state.DetailReducer)
  const {ID, slugId} = useParams()
  const [currentImg, setCurrentImg] = useState(detail?.main_image)


  useEffect(() => {
    dispatch(getDetail(Number(ID)))
    dispatch(getSimilarProducts(slugId))
    return () => {
      dispatch(resetDetail())
    }
  }, [ID])

  if (isError) return <TextErrorrr>{isError}</TextErrorrr>
  if (isLoading) return <DetailSkeleton/>
  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" mt={{base: "12.69px", md: "0"}}
            flexDir={{base: "column-reverse", lg: "row"}}>
        <Box w={{base: "full", lg: "50%"}}>
          <Flex display={{base: "none", lg: "flex"}} flexWrap='wrap' pb="84px" w='full' fontFamily="Roboto"
                fontWeight="400" alignItems='center'
                fontSize="12px">
            <Link to={'/'}><Text color='#4983F6' fontSize='12px' fontWeight='400'>Главная</Text></Link>
            <Box mx='2px' color='#394A6C' fontSize='22px'><BsDot/></Box>
            <Link to={NavigationBySub(detail.category?.name, detail.category?.id, "Все")}>
              <Text color='#4983F6' fontSize='12px' fontWeight='400'>{detail.category?.name}</Text>
            </Link>
            <Box mx='2px' color='#394A6C' fontSize='22px'><BsDot/></Box>
            <Link to={NavigationBySub(detail.category?.name, detail.category?.id, detail.sub_category?.name)}>
              <Text color='#4983F6' fontSize='12px' fontWeight='400'>{detail.sub_category?.name}</Text>
            </Link>
            <Box mx='2px' color='#394A6C' fontSize='22px'><BsDot/></Box>
            <a href={'#'}>
              <Text color='#4983F6' fontSize='12px' fontWeight='400'>{detail.name}</Text>
            </a>
          </Flex>
          <Text as="span" w="78px" h="30px" px="10px" py="7px" textAlign="center" bg="rgba(230, 98, 70, 0.1)"
                borderRadius="5px" fontFamily="Roboto" fontWeight="500" fontSize="12px" color="#FF3A10"
                opacity="0.7">{detail.in_stock ? 'В наличии' : 'Нету в наличии'}</Text>
          <Text as="h2" my={{base: "20px", lg: "30px"}} fontFamily="Roboto" fontWeight="500" fontSize="30px"
                color="#394A6C">{detail.name}</Text>
          <Text as="h3" fontFamily="Roboto" fontWeight="500" fontSize="22px"
                color="#394A6C">{detail.price}&nbsp;руб.</Text>
          <Flex mt={{base: "20px", lg: "30px"}} mb={{base: "46px", lg: "80px"}} alignItems="center"
                fontFamily="SF Pro Display,sans-serif"
                fontWeight="600" fontSize="12px" color="#394A6C">
            <Text opacity="0.5">Модель:</Text>
            <Text px="5px" opacity="0.8">{detail.sub_category?.name}</Text>
          </Flex>
          <ToCartButton/>
        </Box>
        <Box mb={{base: "45.73px", lg: "0"}} w={{base: "100%", md: "400px", lg: "470px"}}>
          <Flex justifyContent="center" alignItems="center" w="full"
                h={{sm: '370px', base: '280px'}}
                border="1px solid #D2D2D2"
                borderRadius="24px">
            <Fancybox options={{Carousel: {infinite: true}}}>
              <a data-fancybox="gallery" href={currentImg || detail.main_image}>
                <Image alt="img" src={currentImg || detail.main_image} w={{lg: 470, md: 400, sm: 400, base: 400}}
                       rounded='24px'
                       h={{sm: '360px', base: '270px'}}/>
              </a></Fancybox></Flex>
          <Box w="full" display="flex" alignItems="center" justifyContent="space-between" pt="15px"
               position="relative">
            <Box><Center
              fontSize="25px" color="#394A6C" id="mySwiper"
              className="swiper-button-prev"><HiOutlineArrowSmLeft/></Center></Box>
            <Swiper
              slidesPerView={5}
              spaceBetween={10}
              modules={[Navigation]}
              navigation={{
                prevEl: '#mySwiper.swiper-button-prev',
                nextEl: '#mySwiper.swiper-button-next'
              }}
              id="mySwiper"
              breakpoints={{
                1280: {
                  slidesPerView: 5
                },
                992: {
                  slidesPerView: 3
                },
                768: {
                  slidesPerView: 5
                },
                480: {
                  slidesPerView: 5
                },
                400: {
                  slidesPerView: 3
                },
                350: {
                  slidesPerView: 3
                },
                320: {
                  slidesPerView: 2
                },
              }}
            >
              {detail.images?.map((photo, idx) => (
                <SwiperSlide key={idx}>
                  <Center borderRadius="3.9186px" w="58.78px" h="58.78px"
                          onMouseOver={() => setCurrentImg(photo.image)}
                          onClick={() => setCurrentImg(photo.image)}
                          border={photo.image === currentImg ? '1.3062px solid #E66246;' : '1.653099px solid rgba(0, 0, 0, 0.2)'}
                          transition='.2s'>
                    <img width="40.94px" height="38.53px" src={photo.image} alt=""/></Center>
                </SwiperSlide>
              ))}
            </Swiper>
            <Box><Center fontSize="25px" color="#394A6C" id="mySwiper"
                         className="swiper-button-next"><HiOutlineArrowSmRight/></Center></Box>
          </Box>
        </Box>
      </Flex>
      <Description/>
      <Box>
        <Flex w="full" justifyContent="space-between">
          <TitleComponent title={"Похожие товары"} shadowTitle={"Похожие "} visible={true}/>
          <Flex mt="10px">
            <Center display={{base: "none", sm: "flex"}} id="mySwiperTwo" fontSize="25px"
                    className="swiper-button-prev"><HiOutlineArrowSmLeft/></Center>
            <Center id="mySwiperTwo" fontSize="25px" className="swiper-button-next"><HiOutlineArrowSmRight/></Center>
          </Flex>
        </Flex>
        <Box w="full" display="flex" alignItems="center" justifyContent="space-between" pt="12px"
             mt={{base: "34px", lg: "0"}} position="relative">
          <Swiper
            slidesPerView={5}
            spaceBetween={20}
            modules={[Navigation]}
            navigation={{
              prevEl: '#mySwiperTwo.swiper-button-prev',
              nextEl: '#mySwiperTwo.swiper-button-next'
            }}
            id="mySwiperTwo"
            breakpoints={{
              1280: {
                slidesPerView: 5
              },
              992: {
                slidesPerView: 4
              },
              768: {
                slidesPerView: 5
              },
              480: {
                slidesPerView: 3
              },
              400: {
                slidesPerView: 2
              },
              320: {
                slidesPerView: 2
              },
            }}
          >
            {similarProducts?.map(product => (
              <SwiperSlide key={product.id}>
                <Box pos="relative" w={{base: "129.43px", lg: "160px"}}
                     h={{base: "145.61px", lg: "180px"}} borderRadius="14px">
                  <Center w="80px" h="32px" p="6px 10px" bg="rgba(0, 0, 0, 0.4);" borderRadius="38px"
                          fontFamily='SF Pro Display,sans-serif' fontWeight="800" fontSize="10px" color="#FFFFFF"
                          pos="absolute" top="9px" left="10px">{product.price}&nbsp;руб.</Center>
                  <Link to={NavigateToDetail(product)}>
                    <Image src={product.main_image} w={{base: "129.43px", lg: "160px"}}
                           h={{base: "145.61px", lg: "180px"}}
                           borderRadius="14px" border="2px solid rgba(38, 51, 77, 0.1)" alt="img"/>
                  </Link>
                </Box>
                <Text mt="15px" fontFamily='SF Pro Display,sans-serif' fontWeight="500" fontSize="12px"
                      color="#394A6C">{product.name}</Text>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </>
  );
};

export default DetailPage;