import React from 'react';
import {Box, Text, Wrap, WrapItem, Center} from "@chakra-ui/react";
import TitleComponent from "../../../components/TitleComponent";
import FooterForm from "../../../components/FooterForm";
import {deliveryData} from "../../../helpers/admin-data";

const Delivery = () => {
    return (
        <Box rounded='10px' bg={{md: 'white', base: 'transparent'}} mt={{base:"6px",md:"0"}} py={{md: '19px', base: '0'}}
             px={{md: '36px', base: '0'}}>
            <Box display={{md: 'block', base: 'none'}}><TitleComponent title={'Доставка'} shadowTitle='Доставка' /></Box>
            <Box display={{md: 'none', base: 'block'}} py={8}><TitleComponent title={'Доставка'} shadowTitle='Доставка' visible={true}/></Box>
            <Box w='full' fontFamily='Roboto' my={2} pr={{md: '6%', base: '0'}}>
                <Text
                    pr={{lg: '24%', base: '0'}}
                    fontWeight='400'
                    fontSize='16px'
                    color='#394A6C'
                    lineHeight={'30px'}
                >Мы осуществляем доставку стройматериалов по Москве и Московской области в течение одного дня с момента
                    оформления заказа. Стоимость доставки зависит от
                    веса заказа и местонахождение объекта, и предварительно оговаривается с оператором
                    интернет-магазина.</Text>
                <Text
                    mt='18px'
                    fontWeight='400'
                    fontSize='16px'
                    color='#6B7A99'
                    lineHeight={'30px'}
                >Вы можете заказать доставку стройматериалов на удобный Вам день и в примерное время - первая половина
                    дня, середина дня или вечер. Более точно спланировать время доставки, исходя из ситуации на дорогах
                    Москвы, мы к
                    сожалению не можем. По Вашему пожеланию, мы можем осуществить доставку в выходные дни:
                    субботу и воскресенье.</Text>
                <Text mt="100px" mb="27px" fontFamily="Roboto" fontWeight="500" fontSize="20px" color="#394A6C">Своевременная доставка по
                    Москве и Московской области.</Text>
                <Wrap spacing={{base:"10px",md:"31px"}}>
                    {deliveryData.map(el => (
                        <WrapItem key={el.item}>
                            <Center p="15px 30px" w="full" h="60px" border="2px solid rgba(38, 51, 77, 0.1)" color="#394A6C"
                                    fontFamily="Roboto" fontWeight="400" fontSize="16px" borderRadius="10px">
                                {el.item}
                            </Center>
                        </WrapItem>
                    ))}
                </Wrap>
            </Box>

            <Box display={{md: 'none', base: 'block'}}>
                <FooterForm/>
            </Box>
        </Box>
    );
};

export default Delivery;