import React, {useState} from 'react';
import {Box, Button} from "@chakra-ui/react";
import {VscSettings} from "react-icons/vsc";
import Placement from "../../Modal/Placement";
import SimpleSorting from "../../SimpleSorting/SimpleSorting";

const FilterBtn = () => {
  const [isOpen, setIsOpen] = useState(false)
  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)
  return (
    <Box>
      <Button
        onClick={onOpen}
        display={{md: 'none', base: 'flex'}}
        alignItems='center'
        rounded='31px' px='22px' mx='2.5px' h='40px'
        bg={'#0000000D'}
        color={'#E66246'}
        mr={2} w='91px'
        size='md'
        fontSize='12px' fontWeight='700'
      ><Box color={'#E66246'} fontSize='1.2rem' mr={1}><VscSettings/></Box>
        Фильтр</Button>

      <Placement isOpen={isOpen} onClose={onClose}>
       <Box w='full' pb={6}>
         <SimpleSorting/>
       </Box>
      </Placement>

    </Box>
  );
};

export default FilterBtn;