import React from 'react';
import {Box, Flex, Heading, Link, Text} from "@chakra-ui/react";
import {IoLogoWhatsapp} from "react-icons/io";
import {FaTelegramPlane} from "react-icons/fa";
import {MdMarkEmailUnread} from "react-icons/md";
import {ADDRESS_LINK, TELEGRAM_LINK, WHATSAPP_LINK} from "../../../helpers/admin-data";

interface ISocialMedia  {
  id: number;
  icon: any;
  name: string;
  color: string;
  link: string;
}


const socialMedia: ISocialMedia[] = [
  {
    id: 1,
    icon: <IoLogoWhatsapp/>,
    name: 'Whats App',
    color: '#1BC95C',
    link: WHATSAPP_LINK,
  },
  {
    id: 1,
    icon: <FaTelegramPlane/>,
    name: 'Telegram',
    color: '#08A1E3',
    link: TELEGRAM_LINK,
  },
  {
    id: 1,
    icon: <MdMarkEmailUnread/>,
    name: 'Email',
    color: '#E66246',
    link: ADDRESS_LINK,
  },
]

const SocialMedias = () => {
  return (
    <Box>
      <Heading
        color='#40404F' fontSize='22px' fontWeight='700' textAlign='center'
      >Свяжитесь с нами через</Heading>
      <Flex justifyContent='center' my={10}>
        {socialMedia.map(media => (
          <Link href={media.link} mx={3} target='_blank' key={media.id}>
            <Box bg={'#0000000D'} padding='10px' rounded='50%' cursor='pointer'>
              <Flex justifyContent='center' alignItems='center' bg={media.color} rounded='50%'
                    width='48px' height='48px' fontSize='2rem' color='white'
              >{media.icon}</Flex>
            </Box>

            <Text mt='15px' fontSize='12px' fontWeight='500' textAlign='center'>{media.name}</Text>
          </Link>
        ))}
      </Flex>
    </Box>
  );
};

export default SocialMedias;