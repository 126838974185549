import React, {useEffect} from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex, Skeleton,
} from "@chakra-ui/react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getCategories} from "./Categories.Reducer/categoryActions";
import {NavigationBySub} from "../../helpers/path";
import TextErrorrr from "../ui/TextErrorrr";


const Categories = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {slug, sub: subPath} = useParams()
  const {categories, isError, isLoading} = useAppSelector(state => state.CategoryReducer)
  const found: any = categories?.find(c => c.name === slug)
  const defaultIndex: number = categories.indexOf(found)
  useEffect(() => {
    dispatch(getCategories())
  }, [])
  if (isError) return <TextErrorrr>{isError}</TextErrorrr>
  return (
    <Box w='full' fontFamily='Roboto' bg='white'
         boxShadow={'0px 2px 5px rgba(38, 51, 77, 0.03)'}
         rounded='10px' py={1} mb={{md: '30px', base: '0'}}>
      <Accordion allowToggle display={{md: 'block', base: 'none'}} defaultIndex={[defaultIndex]}>
        {isLoading && Array(20).fill(0).map((_, idx) => (
          <Box px={4} key={idx}><Skeleton my={4} h='15px' rounded='5px'/></Box>
        ))}
        {!isLoading && categories?.map(category => (
          <AccordionItem key={category.id} border='none' my={4}>
            <h2>
              <AccordionButton
                onClick={() => navigate(NavigationBySub(category.name, category.id, "Все"))}
                color={slug === category.name ? "#78C22B" : '#6B7A99'} _hover={{bg: 'none', color: '#78C22B'}}>
                <Box
                  as="span" flex='1' textAlign='left' fontWeight='900' fontSize='14px'>
                  {category.name}
                </Box>
                <AccordionIcon/>
              </AccordionButton>
            </h2>
            <AccordionPanel pb={2}>
              <Flex flexDirection='column' pl={4} fontWeight='600' fontSize='12px'>
                {category.sub_category?.map(sub => (
                  <Box key={sub.id} my='5px' color={subPath === sub.name ? '#78C22B' : '#394A6C'}
                       _hover={{color: '#78C22B'}}>
                    <Link to={NavigationBySub(category.name, category.id, sub.name)}>{sub.name}</Link>
                  </Box>
                ))}
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        ))
        }
      </Accordion>
    </Box>
  );
};

export default Categories;