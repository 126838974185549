import React, {useEffect} from 'react';
import {Box, Button, Flex, SimpleGrid, Text, ListItem,
  UnorderedList} from "@chakra-ui/react";
import TitleComponent from "../../../components/TitleComponent";
import ProductCard from "../../../components/ProductCard";
import {useNavigate} from "react-router-dom";
import MoreArrowSvg from "../../../assets/svg/MoreArrowSvg";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getALlProducts} from "../../AllProducts/ProductReducer/ProductsActions";
import SkeletonCard from "../../../components/ProductCard/SkeletonCard";
import TextErrorrr from "../../../components/ui/TextErrorrr";
import GridProducts from "../../../components/GridProducts";

const ProductsMain = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {results, isLoading, isError, page, count} = useAppSelector(state => state.ProductsReducer)

  useEffect(() => {
    dispatch(getALlProducts(page))
  }, [page])
  if (isError) return <TextErrorrr>{isError}</TextErrorrr>
  return (
    <Box mt={20} mb='200px'>
      <Flex justifyContent='space-between' my={{md: 0, base: 10}}>
        <TitleComponent title={'Все товары'} shadowTitle={'Товары'} visible={true}/>
        <Button
          onClick={() => navigate('/products')}
          mt={{md: 4, base: 1}}
          variant='none'
          boxShadow='0px 10px 30px rgba(255, 102, 51, 0.3)'
          rounded='20px' w={{md: '100px', base: '120px'}}
          bg='#E66246'
          padding='10px 20px'
          fontSize='12px'
          fontWeight='900'
          color='white'
        >Каталог</Button>
      </Flex>

      <Box display={{md: 'block', base: 'none'}} w='full' mb={'50px'}>
       <GridProducts/>
      </Box>

      <Box color={'#465575'} display={{md: 'block', base: 'none'}}>
        <Text mb={'15px'}>
          <span className='titleC'>Керемет</span> - широкий спектр сухих смесей для комфортного ремонта и стройки.
          Ни одно строительство, либо ремонт не обходятся без сухих смесей.
        </Text>

        <Text  fontWeight={'700'} mb={'5px'}>Сухие строительные смеси:</Text>
        <UnorderedList  mb={'15px'}>
         <ListItem>Благоприятный микроклимат.</ListItem>
         <ListItem>Повышает теплоизоляцию.</ListItem>
         <ListItem>Длительный срок хранения.</ListItem>
         <ListItem>Воздухопроницаемость.</ListItem>
         <ListItem>Устойчивость к физическим воздействиям.</ListItem>
         <ListItem>Препятствуют образованию плесени  и грибковых отложений.</ListItem>
        </UnorderedList>

        <Text mb={'15px'}>
          <span className='titleC'>Интернет-магазин Керемет</span> - процесс покупки простой и выгодный, работа – легкая и эффективная, <br /> а результат - надежный и долговечный!
        </Text>
        

        <Text>
          <span className='titleC'>Керемет</span>- Ваш помощник в ремонте и строительстве!
        </Text>
      </Box>

      <Box display={{md: 'none', base: 'block'}} w='full' position='relative' mb={'50px'}>
        <SimpleGrid columns={{xl: 4, lg: 3, md: 2, sm: 3, base: 2}} spacing={{md: '28px', base: '23px 13px'}}>
          {isLoading && <SkeletonCard count={8}/>}
          {!isLoading && results?.slice(0, 6)?.map(product => (
            <ProductCard key={product.id} product={product}/>
          ))}
        </SimpleGrid>
        {results?.length > 6 && <Box
          position='absolute'
          bottom='-25px' zIndex='3'
          left='0' w='full'
        >
          <Button
            onClick={() => navigate('/products')}
            display='flex' alignItems='center' justifyContent='space-between'
            mx='auto' bg='#FFFFFF'
            boxShadow='0px 1px 10px 3px rgba(0, 0, 0, 0.05)'
            rounded='52px' w='110px'
            fontFamily='Roboto'
            fontSize='14px' px={5}
            fontWeight='700'
            color='#40404F'
          >Больше <MoreArrowSvg/></Button>
        </Box>}
      </Box>
      
      <Box color={'#465575'} display={{md: 'none', base: 'block'}} >
        <Text mb={'15px'}>
          <span className='titleC'>Керемет</span> - широкий спектр сухих смесей для комфортного ремонта и стройки.
          Ни одно строительство, либо ремонт не обходятся без сухих смесей.
        </Text>

        <Text  fontWeight={'700'} mb={'5px'}>Сухие строительные смеси:</Text>
        <UnorderedList  mb={'15px'}>
        <ListItem>Благоприятный микроклимат.</ListItem>
         <ListItem>Повышает теплоизоляцию.</ListItem>
         <ListItem>Длительный срок хранения.</ListItem>
         <ListItem>Воздухопроницаемость.</ListItem>
         <ListItem>Устойчивость к физическим воздействиям.</ListItem>
         <ListItem>Препятствуют образованию плесени  и грибковых отложений.</ListItem>
        </UnorderedList>

        <Text mb={'15px'}>
          <span className='titleC'>Интернет-магазин Керемет</span> - процесс покупки простой и выгодный, работа – легкая и эффективная, <br /> а результат - надежный и долговечный!
        </Text>
        

        <Text>
          <span className='titleC'>Керемет</span>- Ваш помощник в ремонте и строительстве!
        </Text>
      </Box>
    </Box>
  );
};

export default ProductsMain;
