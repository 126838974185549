import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ICategories} from "../../../models/category";

interface CategoryState {
  categories: ICategories[];
  isError: string;
  isLoading: boolean;
}
const initialState: CategoryState = {
  categories: [],
  isError: '',
  isLoading: false
}


export const categorySlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    getCategory(state){
      state.isLoading = true;
    },
    getCategorySuccess(state, action: PayloadAction<ICategories[]>){
      state.isLoading = false;
      state.isError = '';
      state.categories = action.payload
    },getCategoryError(state, action: PayloadAction<string>){
      state.isLoading = false;
      state.categories = [];
      state.isError = action.payload
    },

  }
})


export const CategoryReducer = categorySlice.reducer