import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IProduct} from "../../../models/products";

interface DetailState {
  detail: Partial<IProduct> | IProduct;
  similarProducts: IProduct[];
  isError: string;
  isLoading: boolean;
}

const initialState: DetailState = {
  detail: {},
  similarProducts: [],
  isError: '',
  isLoading: false,
}

export const detailSlice = createSlice({
  name: 'detail/product',
  initialState,
  reducers:{
    getDetail(state){
      state.isLoading = true;
    },
    getDetailSuccess(state, action: PayloadAction<IProduct>){
      state.isLoading = false;
      state.isError = '';
      state.detail = action.payload;
    },
    getSimilarProducts(state, action: PayloadAction<IProduct[]>){
      state.isLoading = false;
      state.similarProducts = action.payload;
    },
    getDetailError(state, action: PayloadAction<string>){
      state.isLoading = false;
      state.detail = {};
      state.isError = action.payload;
    },
    resetDetail(state){
      state.detail = {};
      state.similarProducts = [];
      state.isError = '';
    }
  }
})

export const DetailReducer = detailSlice.reducer