import React, {useEffect, useState} from 'react';
import {Box, Button, Divider, Flex, Heading, Skeleton, Text} from "@chakra-ui/react";
import {BsDot} from "react-icons/bs";
import SubProductCard from "./SubProductCard";
import FilterBtn from "../../components/ui/FilterBtn";
import FooterForm from "../../components/FooterForm";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import SkeletonSubCard from "./SubProductCard/SkeletonSubCard";
import {getProductsBySlug, getSubProductsPage} from "../AllProducts/ProductReducer/ProductsActions";
import {Link, useNavigate, useParams} from "react-router-dom";
import {getSubcategories} from "./SubCategoryReducer/subcategoryActions";
import {NavigationBySub} from "../../helpers/path";
import TextErrorrr from "../../components/ui/TextErrorrr";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";


const SubCategory = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {slug, slugId, sub} = useParams()
  const {sub_category, isLoading: loading} = useAppSelector(state => state.SubCategoryReducer)
  const [activeSort, setActiveSort] = useState<string | undefined>("Все")
  const {isError, isLoading, products, page, count} = useAppSelector(state => state.SubProductsReducer)


  useEffect(() => {
    dispatch(getSubcategories(Number(slugId)))
  }, [slugId])

  useEffect(() => {
    setActiveSort(sub)
    dispatch(getProductsBySlug(Number(slugId), sub, page))
  }, [sub, slugId, page])


  if (isError) return <TextErrorrr>{isError}</TextErrorrr>
  return (
    <Box w='full'>
      <Flex fontFamily='Roboto' w='full' mb={2} display={{md: 'flex', base: 'none'}} alignItems='center'>
        <Link to={'/'}><Text color='#4983F6' fontSize='12px' fontWeight='400'>Главная</Text></Link>
        <Box mx='2px' fontSize='22px'><BsDot/></Box>
        <Text color='#4983F6' fontSize='12px' fontWeight='400'>{slug}</Text>
      </Flex>
      <Flex
        w='full' justifyContent='space-between'
        alignItems={{lg: 'start', base: 'start'}} my={{lg: 8, base: 4}}
        flexDirection={{lg: 'row', base: 'column-reverse'}}>
        <Heading
          noOfLines={3} color='#4D5E80' fontSize={{md: '30px', base: '24px'}}
          fontWeight='700' mt={{lg: 0, base: 8}} mr={4}
        >{slug}</Heading>
        <Flex
          maxW={{lg: '70%', base: '100%'}}
          // overflowX='auto' w={{lg: '429px', md: '100%', base: '95vw'}} className='unscroll'
        >
          {!loading && <Flex w='100%' flexWrap='wrap'>
            <FilterBtn/>

            <Button
              onClick={() => navigate(NavigationBySub(slug, slugId, "Все"))}
              variant='none' mb={2}
              rounded='23px' px='22px' mx='2.5px' h={{md: '32px', base: '40px'}}
              bg={{
                md: activeSort === "Все" ? '#78C22B' : 'transparent',
                base: activeSort === "Все" ? '#78C22B33' : 'transparent'
              }}
              color={{
                md: activeSort === "Все" ? '#FFFFFF' : 'rgba(57,74,108,0.6)',
                base: activeSort === "Все" ? '#3C8700' : 'rgba(57,74,108,0.6)'
              }}
              _hover={{bg: '#78C22B26'}}
              fontSize={{md: '12px', base: '13px'}} fontWeight='700'
            >Все</Button>


            {sub_category?.map(s => (
              <Button
                onClick={() => navigate(NavigationBySub(slug, slugId, s.name))}
                key={s.id}
                variant='none' mb={2}
                rounded='23px' px='22px' mx='2.5px' h={{md: '32px', base: '40px'}}
                bg={{
                  md: activeSort === s.name ? '#78C22B' : 'transparent',
                  base: activeSort === s.name ? '#78C22B33' : 'transparent'
                }}
                color={{
                  md: activeSort === s.name ? '#FFFFFF' : 'rgba(57,74,108,0.6)',
                  base: activeSort === s.name ? '#3C8700' : 'rgba(57,74,108,0.6)'
                }}
                _hover={{bg: '#78C22B26'}}
                fontSize={{md: '12px', base: '13px'}} fontWeight='700'
              >{s.name}</Button>
            ))}
          </Flex>}
          {loading && <Skeleton h='20px' w='429px' rounded='5px'/>}
        </Flex>
      </Flex>

      <Flex
        flexDirection='column' mb='75px'
        rounded='10px' bg='white' w='full'
      >
        {isLoading && !products.length && <SkeletonSubCard/>}
        {products?.map(product => (
          <SubProductCard key={product.id} product={product}/>
        ))}

      </Flex>

      {!isLoading && !!products.length && <Flex alignItems='center' justifyContent='center' mt={3}>
        <Button
          onClick={() => dispatch(getSubProductsPage(page - 1))}
          isDisabled={page === 1}
          bg='#E66246' color='white' fontSize='1.2rem'
          variant='none' _active={{bg: '#E66246CC'}}
        ><IoIosArrowBack/></Button>
        <Text mx={2} fontFamily='Roboto' fontWeight='400' fontSize='16px'>{page} / {count}</Text>
        <Button
          onClick={() => dispatch(getSubProductsPage(page + 1))}
          isDisabled={page === count}
          bg='#E66246' color='white' fontSize='1.2rem'
          variant='none' _active={{bg: '#E66246CC'}}
        ><IoIosArrowForward/></Button>
      </Flex>}

      {!products.length &&
        <Text w='full' textAlign='center' h='100px' color='#4D5E80' fontSize="14px" fontFamily="Roboto" fontWeight='500'
        >В этой категории нет товаров</Text>}
      <Box display={{md: 'none', base: 'block'}}>
        <FooterForm/>
      </Box>
    </Box>
  );
};

export default SubCategory;