import React from 'react';
import {Box, Flex, Link, Text} from "@chakra-ui/react";
import TitleComponent from "../../components/TitleComponent";
import MainCategory from "./MainCategory/MainCategory";
import ProductsMain from "./ProductsMain/ProductsMain";
import MainTools from "../../components/MainTools/MainTools";
import FooterForm from "../../components/FooterForm";
import {IoLogoWhatsapp} from "react-icons/io";
import {WHATSAPP_LINK} from "../../helpers/admin-data";

const Home = () => {
  return (
    <Box position='relative'>
      <TitleComponent title={'Стройматериалы'} shadowTitle={'керемет'}/>
      <MainCategory/>
      <Box display={{md: 'none', base: 'block'}} mt={4}>
        <MainTools/>
      </Box>
      <ProductsMain/>
      <Box display={{md: 'none', base: 'block'}}>
        <FooterForm/>
      </Box>

      <Box position='fixed' bottom={{md: '30px', base: '100px'}} right='14px'>
        <Link href={WHATSAPP_LINK} target='_blank'>
          <Flex bg={'#1BC95C4C'} padding='10px' rounded='50%' cursor='pointer'>
            <Flex justifyContent='center' alignItems='center' bg={'#1BC95C'} rounded='50%'
                  width='48px' height='48px' fontSize='2rem' color='white'
            ><IoLogoWhatsapp/>
            </Flex>
          </Flex>
        </Link>
      </Box>
    </Box>
  );
};

export default Home;