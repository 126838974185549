import React from 'react';

const SvgLine = () => {
    return (
        <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_111_2462)">
                <path d="M15.5 2.5L9.5 6.5L3.5 2.5" stroke="#E66246" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M15.5 5.5L9.5 9.5L3.5 5.5" stroke="#E66246" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_111_2462">
                    <rect width="12" height="18" fill="white" transform="matrix(0 1 -1 0 18.5 0)"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default SvgLine;