import React, {useState} from 'react';
import {Button, Text, useToast} from "@chakra-ui/react";
import AddToBasketBtn from "../../../components/ui/AddToBasketBtn";
import {addToCart, StatusType} from "../../Basket/BasketReducer/basketActions";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import Loader from "../../../components/Loader";
import {creatorToast, ErrorToast, SuccessToast} from "../../../components/ui/ToastStatus";
import {BsCheck} from "react-icons/bs";

const ToCartButton = () => {
  const {detail} = useAppSelector(state => state.DetailReducer)
  const {cart} = useAppSelector(state => state.CartReducer)
  const found = cart?.some(c => c.product.id === detail.id)

  const dispatch = useAppDispatch()
  const toast = useToast()
  const [isLoading, setLoader] = useState<boolean>(false)

  const pendingToCart = () => setLoader(true)
  const successAdded = () => {
    toast(creatorToast(SuccessToast, 'success'))
    setLoader(false)
  }
  const errorAdded = () => {
    toast(creatorToast(ErrorToast, "error"))
    setLoader(false)
  }
  const statusFn = (status: StatusType) => {
    switch (status) {
      case 'pending':
        pendingToCart()
        return;
      case 'success':
        successAdded()
        return;
      case 'rejected':
        errorAdded()
        return;
    }
  }

  const addToBasket = (onOpen: () => void) => {
    dispatch(addToCart(Number(detail.id), 1, onOpen, statusFn))
  }
  return found ? <Button
            variant="none" p="10px 90px" fontFamily='Roboto'
            fontWeight="900" fontSize="14px" color="#FFFFFF" bg="#E66246"
            borderRadius="6px" boxShadow="0px 10px 30px rgba(255, 102, 51, 0.3)"
          >В корзине <Text fontSize="1.2rem"><BsCheck/></Text></Button>
          :
          <AddToBasketBtn addToBasket={addToBasket}>
            <Button
              variant="none" p="10px 90px" fontFamily='Roboto'
              fontWeight="900" fontSize="14px" color="#FFFFFF" bg="#E66246"
              borderRadius="6px" boxShadow="0px 10px 30px rgba(255, 102, 51, 0.3)"
            >{isLoading ? <Loader/> : 'В корзину'}</Button>
          </AddToBasketBtn>
};

export default ToCartButton;