import React from 'react';
import {Box, Flex, Text} from "@chakra-ui/react";
import {NavLink} from "react-router-dom";
import NavBarHome from "../../../assets/svg/NavBarHome";
import NavBarCategory from "../../../assets/svg/NavBarCategory";
import NavBarBasket from "../../../assets/svg/NavBarBasket";
import NavBarInfo from "../../../assets/svg/NavBarInfo";
import {useAppSelector} from "../../../hooks/redux";


const MainNavBar = "Главная"
const CategoryNavBar = "Категории"
const CartNavBar = "Корзина"
const InfoNavBar = "Инфо"
type NavbarTitleType = typeof MainNavBar | typeof CartNavBar | typeof CategoryNavBar | typeof InfoNavBar

interface INavbar {
  id: number;
  icon: JSX.Element;
  title: NavbarTitleType;
  path: string;
}

const navbar: INavbar[] = [
  {
    id: 1,
    icon: <NavBarHome/>,
    title: MainNavBar,
    path: '/',
  },
  {
    id: 2,
    icon: <NavBarCategory/>,
    title: CategoryNavBar,
    path: '/category',
  },
  {
    id: 3,
    icon: <NavBarBasket/>,
    title: CartNavBar,
    path: '/basket',
  },
  {
    id: 4,
    icon: <NavBarInfo/>,
    title: InfoNavBar,
    path: '/info',
  },
]


const MobileNavBar = () => {
  const {cart} = useAppSelector(state => state.CartReducer)
  return (
    <Flex
      justifyContent='space-between' alignItems='end' pt={4} pb={6} px={{sm: 8, base: 6}}
      boxShadow='0px -1px 8px rgba(0, 0, 0, 0.05)' zIndex='10'
      position='fixed' bottom='-10px' left='0' width='full' height='100px'
      bg='white' borderTop='15px' overflow='hidden' w='full' className='navbar-menu'>
      {navbar.map(nav => (
        <NavLink to={nav.path} key={nav.id}>
          <Flex flexDirection='column' alignItems='center' position='relative'>
            <Box> {nav.icon} </Box>
            <Text
              mt={2} fontFamily='Roboto' fontSize='12px' fontWeight='500'
            >{nav.title}</Text>
            { nav.title === CartNavBar && !!cart?.length && <Flex
              justifyContent='center' alignItems='center'
              w='18px' h='18px'
              bg='#F53025'
              position='absolute' top='-8px' right='-8px'
              rounded='50%' color='white'
              fontFamily='Roboto' fontWeight="500" fontSize='11px'
            >{cart?.length}</Flex>}
          </Flex>
        </NavLink>
      ))}
    </Flex>
  );
};

export default MobileNavBar;