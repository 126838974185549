import React from 'react';
import {Box, Flex, Skeleton, SkeletonText} from "@chakra-ui/react";

const SkeletonSubCard = () => {
  return (
    <>
      {Array(8).fill(0).map((_, idx) => (
        <Flex px={{md: 5, base: 2}} mt={3} key={idx}>
          <Skeleton h='100px' w={{md :'130px', sm: '100px',base: '80px'}} mx={4}/>
          <Box w='70%'>
            <SkeletonText mt='3' noOfLines={3} spacing='2' skeletonHeight='3' />
          </Box>
        </Flex>
      ))}
    </>
  );
};

export default SkeletonSubCard;