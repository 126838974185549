import {AppDispatch} from "../../../store/store";
import {cartSlice} from "./basket.slice";
import $api from "../../../components/api/Api";
import {RootCartResponse} from "../../../models/cart";
import {
  creatorToast,
  ErrorDeletedToast,
  SuccessDeletedToast
} from "../../../components/ui/ToastStatus";
import {addToStorage, getFromStorage} from "../../../helpers/storageFn";

export const getCart = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(cartSlice.actions.getCart())
    dispatch(cartSlice.actions.getCartSuccess(getFromStorage('cart')))
  } catch (e: any) {
    dispatch(cartSlice.actions.getCartError(e.response?.data?.message))
    console.log(e)
  }
}

export type StatusType = 'pending' | 'success' | 'rejected'

export const addToCart = (product_id: number, quantity: number = 1, onOpen: any, statusFn: (s: StatusType) => void) => {
  return async (dispatch: AppDispatch) => {
    try {
      statusFn('pending')
      const response = await $api.post<RootCartResponse>('cart/', {quantity, product_id,})
      dispatch(cartSlice.actions.getAddedCard(response.data))
      const cart = getFromStorage('cart') || []
      addToStorage('cart', [...cart, response.data])
      onOpen()
      statusFn('success')
    } catch (e: any) {
      statusFn('rejected')
      console.log(e)
    }
  }
}

export const changeFromCart = (product_id: number, quantity: number, renderCart: boolean = false) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await $api.put<RootCartResponse>('cart/', {quantity, product_id})
      if (response.status) {
        dispatch(cartSlice.actions.getAddedCard(response.data));
        const cart: RootCartResponse[] = getFromStorage('cart') || [];
        const result = cart.map(el => el.product.id === product_id ? response.data : el);
        console.log('result: ',result)
        addToStorage('cart', result);
        if (renderCart) dispatch(getCart())
      }
    } catch (e: any) {
        console.log(e)
      }
    }
  }

export const deleteFromCart = (product_id: number, toast: any) => {
  return async (dispatch: AppDispatch) => {
    try {
      toast(creatorToast(SuccessDeletedToast, 'success'))
      const cart: RootCartResponse[] = getFromStorage('cart') || []
      const result = cart.filter(el => el.product.id !== product_id)
      addToStorage('cart', result)
      dispatch(getCart())
    } catch (e: any) {
      toast(creatorToast(ErrorDeletedToast, 'error'))
      console.log(e)
    }
  }
}


