import {AppDispatch} from "../../../store/store";
import $api from "../../../components/api/Api";
import {IProduct, RootProducts} from "../../../models/products";
import {detailSlice} from "./detail.slice";


export const getSimilarProducts = (category_id: any) => async (dispatch: AppDispatch) => {
  try {
    const response = await $api.get<RootProducts>(`categories/${category_id}/products/`)
    dispatch(detailSlice.actions.getSimilarProducts(response.data.results))
  } catch (e: any) {
    console.log(e)
    dispatch(detailSlice.actions.getDetailError(e.response?.data?.message || e.detail))
  }
}

export const getDetail = (id: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(detailSlice.actions.getDetail())
    const response = await $api.get<IProduct>(`products/${id}`)
    dispatch(detailSlice.actions.getDetailSuccess(response.data))
  } catch (e: any) {
    console.log(e)
    dispatch(detailSlice.actions.getDetailError(e.response?.data?.message || e.detail))
  }
}


export const resetDetail = () => async (dispatch: AppDispatch) => {
  dispatch(detailSlice.actions.resetDetail())
}
