import {IProduct, RootProducts} from "../../models/products";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {BASE_URL} from "../../components/api/Api";


export const productsApi = createApi({
  reducerPath: `products/api`,
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, api) => {
      headers.set("Content-Type", 'application/json')
    }
  }),
  refetchOnFocus: true,
  endpoints: build => ({
    searchProducts: build.query<IProduct[], string>({
      query: (search: string) => ({
        url: `products/?search=${search}`,
      }),
      transformResponse: (response: RootProducts) => response.results
    })
  })
})

export const {useSearchProductsQuery} = productsApi