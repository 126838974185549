import React, {useState} from 'react';
import {Box, Button, Collapse, Heading, Flex, Divider} from "@chakra-ui/react";
import SvgLine from "../../../assets/svg/SvgLine";
import {useAppSelector} from "../../../hooks/redux";

const Description = () => {
  const {detail} = useAppSelector(state => state.DetailReducer)
  const [showOne, setShowOne] = useState(false)
  const [showTwo, setShowTwo] = useState(false)
  const handleToggleOne = () => setShowOne(!showOne)
  const handleToggleTwo = () => setShowTwo(!showTwo)
  return (
    <Box display="flex" mb={{base: "116px", lg: "120px"}} alignItems="start" flexDir={{base: "column", lg: "row"}}>
      <Box w="full" h="auto" minH="197px" bg="#FFFFFF"
           boxShadow="0px 2px 3px rgba(0, 0, 0, 0.1)"
           borderRadius="18px"
           pos="relative"
           mt={{base: "57px", lg: "117.22px"}}
      >
        <Box w="full" overflowY='auto' px="25px" py="15px">
          <Collapse animateOpacity startingHeight={150} in={showOne}>
            <Heading pb="12px" fontFamily='SF Pro Display' fontWeight="900" fontSize="14px" color="#4D5E80">
              Описание
            </Heading>
            <Box fontFamily='Roboto' fontWeight="400" fontSize="12px" color="#6B7A99"
                 lineHeight="25px">{detail.description}</Box>
          </Collapse>
          <Button onClick={handleToggleOne} variant="none" position="absolute" bottom="-20px"
                  left={{base: "30%", lg: "40%"}}
                  right="40%" m="auto" w="110px" h="40px" px="19.5px" py="10px" bg="#FFFFFF"
                  boxShadow=" 0px 1px 10px 3px rgba(0, 0, 0, 0.05)" borderRadius="52px"
                  fontFamily="Roboto"
                  fontWeight="500"
                  fontSize="14px"
                  color="#000000"
                  justifyContent="center"
                  alignItems="center"
                  rightIcon={<Box color="rgba(245, 59, 73, 1)"
                                  transform={showOne ? "rotate(-180deg)" : "rotate(0)"} fontSize="20px">
                    {showOne ? <SvgLine/> : <SvgLine/>}</Box>}>{showOne ? 'Закрыть' : 'Больше'}</Button>
        </Box>
      </Box>
      <Box w="full" h="auto" minH="197px" bg="#FFFFFF"
           boxShadow="0px 2px 3px rgba(0, 0, 0, 0.1)"
           borderRadius="18px"
           pos="relative"
           mt={{base: "55px", lg: "117.22px"}}
           ml={{base: "0", lg: "43px"}}
      >
        <Box w="full" overflowY='auto' px="25px" py="15px">
          <Collapse animateOpacity startingHeight={155} in={showTwo}>
            <Heading pb="12px" fontFamily='SF Pro Display' fontWeight="900" fontSize="14px" color="#4D5E80">
              Характеристики
            </Heading>
            {detail.characteristics?.map((el,idx) => (
              <Flex key={idx} w="full" alignItems="end" justifyContent="space-between" fontFamily="Roboto" fontWeight="400"
                    fontSize="12px" color="#6B7A99">
                <Box>{el.left_side}</Box>
                <Box
                  display="inline-flex"
                  flexGrow={1}
                  height="0"
                  borderBottom="0.5px solid rgba(0, 0, 0, 0.2)"
                />
                <Box>{el.right_side}</Box>
              </Flex>
            ))}
          </Collapse>
          <Button
            onClick={handleToggleTwo} variant="none"
            position="absolute" bottom="-20px"
            left={{base: "30%", sm: "40%"}} right="40%" m="auto" w="110px" h="40px"
            px="19.5px" py="10px"
            bg="#FFFFFF" boxShadow=" 0px 1px 10px 3px rgba(0, 0, 0, 0.05)"
            borderRadius="52px" fontFamily="Roboto"
            fontWeight="500" fontSize="14px" color="#000000"
            justifyContent="center" alignItems="center"
            rightIcon={<Box color="rgba(245, 59, 73, 1)"
                            transform={showTwo ? "rotate(-180deg)" : "rotate(0)"}
                            fontSize="20px">{showTwo ? <SvgLine/> :
              <SvgLine/>}</Box>}>{showTwo ? 'Закрыть' : 'Больше'}</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Description;