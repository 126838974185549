import React, {useState} from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Modal, ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import SocialMedias from "./SocialMedias";
import Placement from "../Modal/Placement";


interface Props {
  title: string;
  shadowTitle: string;
  visible?: boolean
}

const TitleComponent = ({shadowTitle, title, visible = false}: Props) => {
  /** desktop modal **/
  const [isOpen, setIsOpen] = useState(false)
  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)

  /** mobile **/
  const [isOpenMobile, setIsOpenMobile] = useState(false)
  const onOpenMobile = () => setIsOpenMobile(true)
  const onCloseMobile = () => setIsOpenMobile(false)

  return (
    <Flex justifyContent='space-between' w='full' mb={{md: '30px', base: '0'}} alignItems={'center'} pb={8} fontFamily={'Roboto'}>
      <Flex position='relative' w='80%'>
        <Heading
          position={{md:'relative', base: 'absolute'}} left='0' top='0'
          color='#002881' height='full' opacity='0.03' fontSize={{md: '90px',base: '60px'}} fontWeight='900' textTransform='uppercase'
          lineHeight='0.6'
        >{shadowTitle}</Heading>
        <Box  position='absolute' left='0' top='0' height='full'>
          <Heading
            color='#4D5E80' fontSize={{md: '30px', base: '24px'}} mt={{md: '5%', base: '0'}} fontWeight='700'
          >{title}</Heading>
        </Box>
      </Flex>

      <Button
        onClick={onOpen}
        display={{md: visible ? 'none': 'block', base: 'none'}}
        variant='none'
        boxShadow='0px 10px 30px rgba(255, 102, 51, 0.3)'
        rounded='20px' w={'100px'}
        bg='#E66246'
        mt={{md: 'auto', base: '40px'}}
        padding='10px 20px'
        fontSize='12px'
        fontWeight='900'
        color='white'
      >Связаться</Button>


      <Modal isOpen={isOpen} onClose={onClose} motionPreset='slideInBottom' size={{md: 'md', base: 'sm'}} id='modal' >
        <ModalOverlay />
        <ModalContent rounded='24px'>
          <ModalHeader/> <ModalCloseButton />
          <ModalBody py={16}>
            <SocialMedias/>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Button
        onClick={onOpenMobile}
        display={{base: visible ? 'none': 'block',md: 'none'}}
        variant='none'
        boxShadow='0px 10px 30px rgba(255, 102, 51, 0.3)'
        rounded='20px'
        bg='#E66246' w={'120px'}
        mt={{md: 'auto', base: '40px'}}
        padding='10px 20px'
        fontSize='12px'
        fontWeight='900'
        color='white'
      >Связаться</Button>

      <Placement isOpen={isOpenMobile} onClose={onCloseMobile}>
        <SocialMedias/>
      </Placement>

    </Flex>
  );
};

export default TitleComponent;