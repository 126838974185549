import React from 'react';
import {Box, Flex, Skeleton, SkeletonText} from "@chakra-ui/react";
import SkeletonCard from "../../components/ProductCard/SkeletonCard";
import SkeletonSubCard from "../SubCategory/SubProductCard/SkeletonSubCard";

const DetailSkeleton = () => {
  return (
    <Flex
      justifyContent='space-between'
      flexDirection={{md: 'row', base: 'column-reverse'}}
    >
      <Box w={{md: '45%', base: '100%'}}>
        <SkeletonText mt='3' noOfLines={2} spacing='2' skeletonHeight='3'/>
        <SkeletonText mt='40px' noOfLines={4} spacing='2' skeletonHeight='3'/>
        <SkeletonText mt='40px' noOfLines={4} spacing='2' skeletonHeight='3'/>
      </Box>
      <Box w={{md: '45%', base: '100%'}}>
        <Skeleton h='400px'/>
      </Box>
    </Flex>
  );
};

export default DetailSkeleton;