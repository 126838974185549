
interface IHeaderNav {
  name: string,
  path: string
}

export const headerNav: IHeaderNav[] = [
  {
    name: 'Главная',
    path: '/'
  },
  {
    name: 'О компании',
    path: '/about-company'
  },
  {
    name: 'Доставка',
    path: '/delivery'
  },
  {
    name: 'Способы оплаты',
    path: '/payment-method'
  },
]