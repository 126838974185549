
const CART = 'cart'
type cartType = typeof CART

export function addToStorage(key: cartType, value: any){
  sessionStorage.setItem(key, JSON.stringify(value))
}

export function getFromStorage(key: cartType){
  return JSON.parse(sessionStorage.getItem(key) as any)
}

export function removeStorage(key: cartType){
  sessionStorage.removeItem(key)
}