import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ISubCategory} from "../../../models/category";

interface SubcategoryState {
  sub_category: ISubCategory[];
  isError: string;
  isLoading: boolean;
}

const initialState: SubcategoryState = {
  sub_category: [],
  isError: '',
  isLoading: false
}

export const subcategorySlice = createSlice({
  name: 'get/subcategory',
  initialState,
  reducers: {
    getSubcategory(state){
      state.isLoading = true;
    },
    getSubcategorySuccess(state, action: PayloadAction<ISubCategory[]>){
      state.isLoading = false;
      state.isError = '';
      state.sub_category = action.payload;
    },
    getSubcategoryError(state, action: PayloadAction<string>){
      state.isLoading = false;
      state.sub_category = [];
      state.isError = action.payload
    },
  }
})

export const SubCategoryReducer = subcategorySlice.reducer