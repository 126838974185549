import React from 'react';

const SvgEditIcon = () => {
    return (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.16667 3H4.16667C3.24619 3 2.5 3.74619 2.5 4.66667V16.3333C2.5 17.2538 3.24619 18 4.16667 18H15.8333C16.7538 18 17.5 17.2538 17.5 16.3333V11.3333" stroke="#4474FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.91699 10.0833L14.5837 3.41666C15.274 2.72631 16.3933 2.72631 17.0837 3.41666C17.774 4.10702 17.774 5.22631 17.0837 5.91666L10.417 12.5833L6.66699 13.8333L7.91699 10.0833Z" stroke="#4474FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default SvgEditIcon;