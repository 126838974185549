import React from 'react';

const NavBarBasket = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.83952 14.9633H18.6186V15.368H7.42137C7.08169 15.368 6.76226 15.2386 6.53179 15.0181C6.30245 14.7988 6.18016 14.5085 6.18016 14.2133C6.18016 14.0164 6.23278 13.8292 6.32104 13.67L7.6718 11.3424L7.87409 10.9938L7.69505 10.6328L4.11089 3.40469L3.9042 2.98788H3.43896H2.19775V2.58325H4.23625L4.96611 4.06863L5.17211 4.48788H5.63924H20.3642C20.4398 4.48788 20.506 4.51693 20.5498 4.55879L21.0682 4.01681L20.5498 4.55879C20.5924 4.59956 20.6098 4.64757 20.6098 4.6902C20.6098 4.702 20.6053 4.74426 20.5708 4.8259L17.0281 10.9502C17.0279 10.9505 17.0277 10.9509 17.0275 10.9512C16.8228 11.3016 16.4124 11.5587 15.9338 11.5587H8.51653H8.08348L7.86698 11.9338L6.98017 13.4701C6.86688 13.6515 6.84062 13.8388 6.84062 13.9753C6.84062 14.2483 6.95434 14.5031 7.1451 14.6856L7.66352 14.1436L7.1451 14.6856C7.33474 14.867 7.58509 14.9633 7.83952 14.9633ZM16.1362 18.9749C16.1362 18.3631 16.6547 17.8203 17.3774 17.8203C17.7171 17.8203 18.0365 17.9497 18.267 18.1701C18.4963 18.3895 18.6186 18.6797 18.6186 18.9749C18.6186 19.2701 18.4963 19.5603 18.267 19.7797C18.0365 20.0002 17.7171 20.1295 17.3774 20.1295C17.0377 20.1295 16.7183 20.0002 16.4878 19.7797C16.2585 19.5603 16.1362 19.2701 16.1362 18.9749ZM6.18016 18.9749C6.18016 18.3631 6.69869 17.8203 7.42137 17.8203C7.76104 17.8203 8.08047 17.9497 8.31094 18.1701C8.54028 18.3895 8.66257 18.6797 8.66257 18.9749C8.66257 19.2701 8.54028 19.5603 8.31094 19.7797C8.08047 20.0002 7.76104 20.1295 7.42137 20.1295C7.08169 20.1295 6.76226 20.0002 6.53179 19.7797C6.30245 19.5603 6.18016 19.2701 6.18016 18.9749Z" stroke="#616161" strokeWidth="1.5"/>
    </svg>

  );
};

export default NavBarBasket;