import React from 'react';
import {Box, Flex} from "@chakra-ui/react";
import TitleComponent from "../../components/TitleComponent";
import FilterBtn from "../../components/ui/FilterBtn";
import FooterForm from "../../components/FooterForm";
import {useAppSelector} from "../../hooks/redux";
import TextErrorrr from "../../components/ui/TextErrorrr";
import GridProducts from "../../components/GridProducts";

const AllProducts = () => {
  const { isError, type_products} = useAppSelector(state => state.ProductsReducer)

  if (isError) return <TextErrorrr>{isError}</TextErrorrr>
  return (
    <Box>
      <Flex justifyContent='space-between' my={{md: 0, base: 6}}>
        <TitleComponent title={type_products} shadowTitle={'Товары'} visible={true}/>
        <Box display={{md: 'none', base: 'block'}} mt={2}>
          <FilterBtn/>
        </Box>
      </Flex>
      <Box mb='150px'>
        <GridProducts/>
      </Box>
      <Box display={{md: 'none', base: 'block'}}>
        <FooterForm/>
      </Box>
    </Box>
  );
};

export default AllProducts;