
export const PHONE_NUMBER1 = '+79265123397'



// Обратите на формат номера!! должен быть без лишних пробелов, скобок!!
// +79265123397
const WHATSAPP_NUMBER = '+79265123397'

const TELEGRAM_NICKNAME = 'keremet_ru'

const INSTAGRAM_NICKNAME = 'keremet_ru'

export const EMAIL_ADDRESS = 'info@keremet.ru'

export const ADDRESS = '54-й км МКАД (внешняя сторона), д. Марфино, вл. 100. Ближайшая станция метро - Молодежная'

export const SCHEDULE = '10:00-21:00 Пн-Вс'






/** не трогать!! **/

export const PHONE_NUMBER_LINK1 = `tel:${PHONE_NUMBER1}`
export const WHATSAPP_LINK = `https://wa.me/${WHATSAPP_NUMBER}`
export const TELEGRAM_LINK = `https://t.me/${TELEGRAM_NICKNAME}`
export const ADDRESS_LINK = `mailto:${EMAIL_ADDRESS}`
export const INSTAGRAM_LINK = `https://www.instagram.com/${INSTAGRAM_NICKNAME}/`






/** ABOUT COMPANY PAGE STATIC DATA **/
export const advantagesData = [
    {item: "большой выбор предлагаемой продукции;"},
    {item: "отсутствие необходимости покупки мелких партий стройматериалов в узкоспециализированных строительных торговых точках;"},
    {item: "уверенность в наличии сертификатов на предлагаемую магазином продукцию, и таким образом, гарантированное соответствие всех заявленных производителем характеристик;"},
    {item: "доставка приобретенных стройматериалов любых объемов по указанному заказчиком адресу в любую точку Москвы или Московской области;"},
    {item: "возможность оплаты приобретенной продукции удобным для покупателя способом;"},
    {item: "возможность выбора склада, с которого будет отгружена продукция. Экономия на транспортных расходах;"},
    {item: "ВЫГОДНЫЕ условия сотрудничества с постоянными клиентами."},
]

/** DELIVERY STATIC DATA **/
export const deliveryData = [
    {item: "Доставка газель до 2.5тонн от 2500р"},
    {item: "Доставка до 3.5тонн от 3000р"},
    {item: "Доставка 5тонн от 3500р"},
    {item: "Доставка манипулятор 10тонн от 8 000р"},
]
