import React, {useEffect, useState} from 'react';
import {Box, Button} from "@chakra-ui/react";
import {sortingData, SortingTypes} from "./sort";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useAppDispatch} from "../../hooks/redux";
import {getSortingProducts} from "../../pages/AllProducts/ProductReducer/ProductsActions";



const SimpleSorting = () => {
  const dispatch = useAppDispatch()
  const {slug} = useParams()
  const navigate = useNavigate()
  const [defButton, setDefButton] = useState<SortingTypes>('По умолчанию')

  const sortingProducts = (sort: SortingTypes) => {
    dispatch(getSortingProducts(sort, navigate, !slug))
    setDefButton(sort)
  }

  return (
    <Box
      display='flex'
      justifyContent={{md: 'space-between', base: 'space-around'}}
      flexWrap='wrap'
      w='full'
      fontFamily='Roboto'
      bg='white'
      boxShadow='0px 2px 5px rgba(38, 51, 77, 0.03)'
      rounded='10px'
      padding={{md: '24px 16px', base: '0'}}
      mb={{md: '30px', base: '0'}}>
      {sortingData?.map(el => (
          <Button
            key={el}
            onClick={() => sortingProducts(el)}
            bg={defButton === el ? '#61BD52' : '#0000001A'}
            color={defButton === el ? '#FFFFFF' : '#40404F'}
            _hover={{
              bg: 'rgba(0,0,0,0.2)',
            }}
            rounded='50px'
            px={{md: 4, base: 5}} py={2} my='7px'
            variant='none'
            fontWeight='700'
            fontSize='12px'>{el}</Button>
        ))}
    </Box>
  );
};

export default SimpleSorting;