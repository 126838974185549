import React from 'react';

const NavBarCategory = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.8152 3.33325H22.0318C23.1358 3.33325 23.8144 3.62252 24.221 4.02911C24.6276 4.4357 24.9168 5.11432 24.9168 6.21825V8.43492C24.9168 9.53885 24.6276 10.2175 24.221 10.6241C23.8144 11.0306 23.1358 11.3199 22.0318 11.3199H19.8152C18.7112 11.3199 18.0326 11.0306 17.626 10.6241C17.2194 10.2175 16.9302 9.53885 16.9302 8.43492V6.21825C16.9302 5.11432 17.2194 4.4357 17.626 4.02911C18.0326 3.62252 18.7112 3.33325 19.8152 3.33325Z" stroke="#616161" strokeWidth="2"/>
      <path d="M11.5702 19.5603H11.5702V19.5649V21.7815C11.5702 22.8855 11.2809 23.5641 10.8743 23.9707C10.4677 24.3773 9.7891 24.6665 8.68516 24.6665H6.4685C5.36457 24.6665 4.68652 24.3773 4.28014 23.97C3.8734 23.5624 3.5835 22.8808 3.5835 21.7699V19.5532C3.5835 18.4493 3.87274 17.7712 4.28002 17.3649C4.68767 16.9581 5.36922 16.6682 6.48016 16.6682H8.69683C9.80111 16.6682 10.4784 16.9576 10.8829 17.3639C11.2874 17.7702 11.5752 18.4502 11.5702 19.5603Z" stroke="#616161" strokeWidth="2"/>
      <path d="M11.5935 7.33825C11.5935 9.55015 9.8004 11.3433 7.5885 11.3433C5.3766 11.3433 3.5835 9.55015 3.5835 7.33825C3.5835 5.12635 5.3766 3.33325 7.5885 3.33325C9.8004 3.33325 11.5935 5.12635 11.5935 7.33825Z" stroke="#616161" strokeWidth="2"/>
      <path d="M24.9167 20.6617C24.9167 22.8736 23.1236 24.6667 20.9117 24.6667C18.6998 24.6667 16.9067 22.8736 16.9067 20.6617C16.9067 18.4498 18.6998 16.6567 20.9117 16.6567C23.1236 16.6567 24.9167 18.4498 24.9167 20.6617Z" stroke="#616161" strokeWidth="2"/>
    </svg>

  );
};

export default NavBarCategory;