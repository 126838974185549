import React, {useState} from 'react';
import {Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay} from "@chakra-ui/react";
import ContentModalToBasket from "../../Modal/ContentModalToBasket";
import ProductImg from "../../../assets/img/product-img.jpg";
import Placement from "../../Modal/Placement";
import {IProduct} from "../../../models/products";
import {useAppDispatch} from "../../../hooks/redux";
import {getCart} from "../../../pages/Basket/BasketReducer/basketActions";

interface Props {
  children: JSX.Element;
  addToBasket: (onOpen: () => void) => void
}

const AddToBasketBtn = ({children, addToBasket}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useAppDispatch()
  const onClose = () => {
    setIsOpen(false)
    dispatch(getCart())
  }
  const onOpen = () => setIsOpen(true)


  /** mobile pop up **/
  const [isOpenMobile, setIsOpenMobile] = useState(false)
  const onCloseMobile = () => {
    setIsOpenMobile(false)
    dispatch(getCart())
  }
  const onOpenMobile = () => setIsOpenMobile(true)
  return (
    <Box>
      <Box onClick={() => addToBasket(onOpen)} display={{sm: 'block', base: 'none'}}>
        {children}
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} motionPreset='slideInBottom' size={{md: 'lg', sm: 'md', base: 'sm'}}>
        <ModalOverlay/>
        <ModalContent rounded='24px'>
          <ModalHeader/> <ModalCloseButton/>
          <ModalBody py={8}>
            <ContentModalToBasket onClose={onClose}/>
          </ModalBody>
        </ModalContent>
      </Modal>


      <Box onClick={() => addToBasket(onOpenMobile)} display={{sm: 'none', base: 'flex'}}>
        {children}
      </Box>
      <Placement isOpen={isOpenMobile} onClose={onCloseMobile}>
        <Box w='full' pb={10}>
          <ContentModalToBasket onClose={onCloseMobile}/>
        </Box>
      </Placement>

    </Box>
  );
};

export default AddToBasketBtn;