import axios from 'axios'

export const BASE_URL = 'https://backend.api-keremet.ru'

const $api = axios.create({
  baseURL: BASE_URL,
})

$api.interceptors.request.use(
  async (config:any) => {
    config.headers = {
      "Content-Type": 'application/json'
    }

    return config;
  },
  (error) => {
    Promise.reject(error)
  }
)

export default $api

