import React from 'react';

const NavBarInfo = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.25 27C22.3251 27 27.25 22.0751 27.25 16C27.25 9.92487 22.3251 5 16.25 5C10.1749 5 5.25 9.92487 5.25 16C5.25 22.0751 10.1749 27 16.25 27Z" stroke="#616161"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.25 15H16.25V22H17.25" stroke="#616161" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className='active-path'/>
      <path d="M16.25 12C17.0784 12 17.75 11.3284 17.75 10.5C17.75 9.67157 17.0784 9 16.25 9C15.4216 9 14.75 9.67157 14.75 10.5C14.75 11.3284 15.4216 12 16.25 12Z" fill="#616161" className='active-path'/>
    </svg>
  );
};

export default NavBarInfo;

