import React from 'react';

const EmptyBasketSvg = () => {
  return (
    <svg width="259" height="259" viewBox="0 0 259 259" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M207.632 60.2175H203.316L166.84 23.7417C163.926 20.8279 159.178 20.8279 156.156 23.7417C153.242 26.6554 153.242 31.4037 156.156 34.4254L181.948 60.2175H77.0531L102.845 34.4254C105.759 31.5117 105.759 26.7633 102.845 23.7417C99.9315 20.8279 95.1831 20.8279 92.1615 23.7417L55.7936 60.2175H51.4769C41.7644 60.2175 21.584 60.2175 21.584 87.8442C21.584 98.3121 23.7423 105.219 28.2748 109.751C30.8648 112.449 33.9944 113.852 37.3398 114.607C40.4694 115.363 43.8148 115.471 47.0523 115.471H211.949C215.294 115.471 218.424 115.255 221.446 114.607C230.511 112.449 237.417 105.974 237.417 87.8442C237.417 60.2175 217.237 60.2175 207.632 60.2175Z" fill="#394A6C"/>
      <path d="M206.013 129.5H52.9872C46.2963 129.5 41.2243 135.435 42.3034 142.018L51.3684 197.487C54.3901 216.049 62.4838 237.417 98.4201 237.417H158.961C195.329 237.417 201.804 219.179 205.689 198.782L216.589 142.342C217.884 135.651 212.812 129.5 206.013 129.5ZM150.22 203.855C148.709 205.473 146.659 206.229 144.5 206.229C142.45 206.229 140.4 205.473 138.781 203.855L129.716 194.682L120.219 204.178C118.6 205.797 116.55 206.552 114.5 206.552C112.341 206.552 110.291 205.797 108.78 204.178C105.543 201.049 105.543 195.977 108.78 192.739L118.277 183.242L109.104 174.177C105.866 170.94 105.866 165.868 109.104 162.738C112.233 159.501 117.305 159.501 120.543 162.738L129.716 171.803L138.457 163.062C141.695 159.825 146.767 159.825 149.896 163.062C153.134 166.192 153.134 171.264 149.896 174.501L141.155 183.242L150.22 192.415C153.458 195.653 153.458 200.725 150.22 203.855Z" fill="#394A6C"/>
    </svg>

  );
};

export default EmptyBasketSvg;