import {IUser} from "../../../models/user";
import {WHATSAPP_LINK} from "../../../helpers/admin-data";
import {NavigateToDetail} from "../../../helpers/path";
import {RootCartResponse} from "../../../models/cart";
import {orderingSlice, TypeOnDelivery} from "./ordering.slice";
import {AppDispatch} from "../../../store/store";
import $api from "../../../components/api/Api";
import {creatorToast, ErrorPostDataToast, SuccessPostDataToast} from "../../../components/ui/ToastStatus";
import {IOrderForm} from "../../../models/order";
import {addToStorage} from "../../../helpers/storageFn";

export const addOrder = (data: IOrderForm, toast: any, navigate: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(orderingSlice.actions.addOrder())
    const res = await $api.post('message_send/', data)
    dispatch(orderingSlice.actions.addedOrder())
    toast(creatorToast(SuccessPostDataToast, 'success'))
    addToStorage('cart', [])
    navigate('/')
  }catch (e: any) {
    dispatch(orderingSlice.actions.addedOrder())
    toast(creatorToast(ErrorPostDataToast, 'error'))
  }
}






export const sendOrderText = (user: IUser, cart: RootCartResponse[], deliveryType: TypeOnDelivery, paymentOnDelivery: boolean) => {
  const listProducts = cart.map((p, idx) => {
    return `
 ${idx + 1})  Назавание товара: ${p.product.name} 
${!!p.product.sub_category ?`Модель: ${p.product.sub_category.name}`: ''} \b
Количество: ${p.quantity} \b
Ссылка на товар: https://keremet.ru${NavigateToDetail(p.product)}\n`
  })

  const userData = `
Номер: ${user.phone} \b
адресс: ${user.address}\b
${user.email && 'почта: ' + user.email} \b
`
  const DELIVERY_TYPE = `
${deliveryType === "Доставка" ? paymentOnDelivery ? 'Оплата при доставке' : 'Онлайн оплата' : 'Оплата при получении'} / ${deliveryType}\n
`

  return `${WHATSAPP_LINK}?text=${encodeURIComponent(`Здравствуйте!\n`)}\n
${encodeURIComponent(`Меня зовут ${user.name},\nя хочу заказать вот эти товары:\n`)} \n  
${encodeURIComponent(listProducts.join(' '))} \n

${encodeURIComponent(DELIVERY_TYPE)}
Мои контактные данные: \n

${encodeURIComponent(userData)}
Спасибо!`
}