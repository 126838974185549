import React, {useEffect} from 'react';
import {Box, Button, Flex, Heading, Skeleton, Text} from "@chakra-ui/react";
import {IoIosArrowForward} from "react-icons/io";
import FooterForm from "../../FooterForm";
import FilterBtn from "../../ui/FilterBtn";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../../hooks/redux";
import {NavigationBySub} from "../../../helpers/path";
import TextErrorrr from "../../ui/TextErrorrr";

const MobileCategories = () => {
  const navigate = useNavigate()
  const {categories, isError, isLoading} = useAppSelector(state => state.CategoryReducer)
  if (isError) return <TextErrorrr>{isError}</TextErrorrr>
  return (
    <Box maxW='container.xl' px={4} py='45px' display={{md: 'none', base: 'block'}}>
      <Flex justifyContent='space-between' alignItems='center' my={6}>
        <Heading fontFamily='Roboto' fontWeight='700' fontSize='24px' color='#4D5E80'>Категории</Heading>
        <FilterBtn/>
      </Flex>
      <Flex
        flexDirection='column' w='full' mb='110px'
        boxShadow='0px 0px 29px rgba(0, 0, 0, 0.07)'
        bg='white' rounded='10px' py={1} mx='auto' px={6}>

        { isLoading && Array(12).fill(0).map((_,idx) => (
          <Skeleton h='30px' my={2} rounded='5px' key={idx}/>
        ))}
        { !isLoading && categories?.map(category => (
          <Flex
            onClick={() => navigate(NavigationBySub(category.name, category.id, "Все"))}
            key={category.id} my='12px' py={1}
            w='full' _active={{bg: 'gray.100'}}
            justifyContent='space-between'
            alignItems='center'
          >
            <Text fontFamily='Roboto' fontWeight='900' fontSize='18px' color='#6B7A99'>{category.name}</Text>
            <Box color='#C3CAD9'><IoIosArrowForward/></Box>
          </Flex>
        ))}
      </Flex>

      <Box display={{md: 'none', base: 'block'}}>
        <FooterForm/>
      </Box>
    </Box>
  );
};

export default MobileCategories;