import React, {useEffect} from 'react';
import SkeletonCard from "../ProductCard/SkeletonCard";
import ProductCard from "../ProductCard";
import {Box, Button, Flex, SimpleGrid, Text} from "@chakra-ui/react";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getProductsPage} from "../../pages/AllProducts/ProductReducer/ProductsActions";
import {IoIosArrowBack, IoIosArrowDown, IoIosArrowForward} from "react-icons/io";
import {useLocation} from "react-router-dom";


const GridProducts = () => {
  const dispatch = useAppDispatch()
  const {pathname} = useLocation()
  const {
    results,
    isLoading,
    page,
    count,
    all_count,
    type_products,
    products
  } = useAppSelector(state => state.ProductsReducer)

  const disables_more_button: boolean = products.length > results.length || page === count || !results.length

  useEffect(() => {
    dispatch(getProductsPage(1))
  }, [pathname])
  return (
    <Box position='relative'>
      <SimpleGrid columns={{xl: 4, lg: 3, md: 2, sm: 3, base: 2}} spacing={{md: '28px', base: '23px 13px'}}>
        {!results.length && isLoading && <SkeletonCard count={8}/>}
        {results?.map((product) => (
          <ProductCard key={product.id} product={product}/>
        ))}
      </SimpleGrid>
      {!disables_more_button && <Flex
        position='absolute'
        bottom='-20px'
        left='0'
        w='100%'
        bg='linear-gradient(0deg, rgba(247,248,250,1) 33%, rgba(247,248,250,0) 100%)'
        h='300px'
        justifyContent='center'
        alignItems='center'
      >
        <Flex flexDirection='column' alignItems='center' mb='-80px' gap='8px'>
          <Button
            onClick={() => dispatch(getProductsPage(page + 1))}
            isDisabled={page === count}
            rounded='40px'
            px='4'
            h='30px'
            display='flex'
            alignItems='center'
            bg='#E66246' color='white' fontSize='0.9rem'
            variant='none' _active={{bg: '#E66246CC'}}
          >Ещё <IoIosArrowDown fontSize='0.9rem'/></Button>
          <Text fontSize='14px'>{page} / {count}</Text>
        </Flex>
      </Flex>}

    </Box>
  );
};

export default GridProducts;