import React from 'react';
import {Button, Flex, Heading} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import ErrorPageSvg from "../../assets/svg/errorPageSvg";

const ErrorPage = () => {
  const navigate = useNavigate()
  return (
    <Flex maxW='container.xl' mx='auto'  mt={24} justifyContent='center' alignItems='center'>
       <ErrorPageSvg/>
    </Flex>
  );
};

export default ErrorPage;