import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import {Box} from "@chakra-ui/react";
import MobileNavBar from "./Header/MobileNavBar";

interface Props {
  children: JSX.Element
}
const Layout = ({children}: Props) => {
  return (
    <Box className='layout' pb={{md: '0', base: '80px'}}>
      <Header/>
      <div className='main'>
        {children}
      </div>
      <Box display={{md: 'none', base: 'block'}}>
        <MobileNavBar/>
      </Box>
      <Footer/>
    </Box>
  );
};

export default Layout;