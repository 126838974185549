import React, {useEffect} from 'react';
import {Box, Container, Flex, Text, Button, Image, Divider} from "@chakra-ui/react";
import TitleComponent from "../../components/TitleComponent";
import {useNavigate} from "react-router-dom"
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getCart} from "./BasketReducer/basketActions";
import BasketCard from "./BasketCard";
import EmptyCart from "../../components/EmptyCart";


const Basket = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {cart, isError, isLoading} = useAppSelector(state => state.CartReducer)
  const totalPrice = cart?.reduce((a, b) => {
    return a + b.quantity * Number(b.product.price)
  }, 0)
  useEffect(() => {
    dispatch(getCart())
  }, [])
  if (isLoading) <Box></Box>
  return (
    <Box mt={{base: "80px", lg: "60px"}} mb="200px">
      <Container maxW="container.lg">
        <TitleComponent title="Корзина" shadowTitle='Корзина' visible={true}/>

        { !!cart?.length && <Box>
          <Box mt={{md: '40px', base: '30px'}} mb="20px">
            {cart?.map(element => (
              <BasketCard element={element} key={element.id}/>
            ))}
          </Box>

          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center">
              <Text fontFamily="Roboto" fontWeight="400" fontSize={{base: "16px", md: "18px"}} color="#4D5E80">
                Общее количество:</Text>&nbsp;
              <Text fontFamily="Roboto" fontWeight="700" fontSize="18px" color="#4D5E80">{cart?.length}</Text>
            </Flex>
            <Flex alignItems="center">
              <Text fontFamily="Roboto" fontWeight="400" fontSize={{base: "16px", md: "18px"}}
                    color="#4D5E80">Итого:</Text>&nbsp;
              <Text fontFamily="Roboto" fontWeight="900" fontSize="18px" color="#78C22B">{totalPrice} руб.</Text>
            </Flex>
          </Flex>

          <Flex justifyContent="end" alignItems="center" mt={{base: "40px", md: "16px"}}>
            <Button onClick={() => navigate("/ordering")} variant="none" w={{base: "full", md: "202px"}} h="50px"
                    fontFamily="Roboto" fontSize="14px" fontWeight="800" color="#FFFFFF" textAlign="center" px="45px"
                    py="15px" bg="#E66246" boxShadow="0px 10px 30px rgba(136, 51, 255, 0.1)" borderRadius="30px">
              Оформить заказ</Button>
          </Flex>
        </Box>}
        { !cart?.length && <EmptyCart/>}
      </Container>
    </Box>
  );
};

export default Basket;