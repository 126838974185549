import React from 'react';
import {Flex, Skeleton} from "@chakra-ui/react";

const SkeletonCard = ({count}: { count: number }) => {
  return (
    <>
      {Array(count).fill(0).map((_, idx) => (
        <Flex key={idx}
          flexDirection='column'
          height={{lg: '330px', bd: '300px', sm: '280px', base: '260px'}}
        >
          <Skeleton h='160px'/>
          <Skeleton h='30px' my={2}/>
        </Flex>
      ))}
    </>
  );
};

export default SkeletonCard;