import React from 'react';
import TitleComponent from "../../components/TitleComponent";
import {
  Box,
  Container,
  Flex,
  Text,
  Divider,
  Button, useToast,
} from "@chakra-ui/react";
import InputOrdering from "../../components/ui/InputOrdering"
import SvgEditIcon from "../../assets/svg/SvgEditIcon"
import {useNavigate} from "react-router-dom";
import {IoLogoWhatsapp} from "react-icons/io"
import {Formik} from "formik";
import * as yup from "yup";
import OrderingChecked from "./OrderingChecked";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {IUser} from "../../models/user";
import {addOrder, sendOrderText} from "./OrderingReducer/oderActions";
import {creatorToast, InputErrorToast} from "../../components/ui/ToastStatus";
import {IOrder} from "../../models/order";
import {NavigateToDetail} from "../../helpers/path";


const Ordering = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const dispatch = useAppDispatch()
  const {cart} = useAppSelector(state => state.CartReducer)
  const {deliveryType, paymentOnDelivery, isLoading} = useAppSelector(state => state.OrderingReducer)
  const validationSchema = yup.object().shape({
    name: yup.string().required('Обязательно'),
    phone: yup.number().typeError("Должно быть числом").required('Обязательно'),
    email: yup.string().email("Введите верный email"),
    address: yup.string().required('Обязательно'),
  })


  const totalPrice = cart?.reduce((a, b) => {
    return a + b.quantity * Number(b.product.price)
  }, 0)

  const sendOrder = (isDisabled: boolean, user: IUser) => {
    if (isDisabled) {
      const orders: IOrder[] = cart.map(el => {
        return {
          name: el.product.name,
          quantity: String(el.quantity),
          link: `https://keremet.ru${NavigateToDetail(el.product)}`,
          model: el.product.category.name
        }
      })
      dispatch(addOrder({
        ...user,
        shipment: deliveryType === 'Доставка',
        payment: paymentOnDelivery,
        orders,
        total: totalPrice
      }, toast, navigate))
    } else {
      toast(creatorToast(InputErrorToast, 'error'))
    }
  }


  return (
    <Box mb="166px" mt={{base: "90px", md: "60px"}}>
      <Formik
        initialValues={{
          name: '', phone: '+', email: '', address: ''
        }}
        validateOnBlur
        validationSchema={validationSchema}
        onSubmit={(data) => console.log(data)}>
        {({values, handleBlur, handleChange, handleSubmit, isValid, dirty}) => (
          <Container maxW="container.xl" p={{base: "auto", lg: "0 30px 0 120px"}}>
            <TitleComponent title="Оформление заказа" shadowTitle='Заказ' visible={true}/>
            <Flex maxW="100%" alignItems="start" justifyContent="space-between" flexDir={{base: "column", lg: "row"}}
                  mt="26px" mb="45px">
              <Box p={{base: "30px 0 11px 0", lg: "48px 35px"}} w="full" h="auto" borderRadius="10px"
                   bg={{base: "transparent", lg: "#FFFFFF"}}>
                <Flex
                  flexWrap='wrap' alignItems="center"
                  justifyContent="space-between"
                  mb={{base: "0", lg: "22px"}}>
                  <InputOrdering
                    placeholder="Напишите имя" value={values.name}
                    name={"name"} title={"Имя *"} type='text' handleBlur={handleBlur}
                    handleChange={handleChange}/>
                  <InputOrdering
                    placeholder="Введите номер" value={values.phone}
                    name={"phone"} title={"Телефон *"} type='tel' handleBlur={handleBlur}
                    handleChange={handleChange}/>
                  <InputOrdering
                    placeholder="Напишите почту" value={values.email}
                    name={"email"} title={"Почта"} type='email' handleBlur={handleBlur}
                    handleChange={handleChange}/>
                  <InputOrdering
                    placeholder="Напишите адрес" value={values.address}
                    name={"address"} title={"Адрес *"} type='text' handleBlur={handleBlur}
                    handleChange={handleChange}/>
                </Flex>
                <Box display={{lg: 'block', base: 'none'}}>
                  <OrderingChecked/>
                </Box>
              </Box>
              <Box ml={{base: "0", lg: "61px"}} w="full">
                <Box mb="16px" h="auto" bg="#FFFFFF" boxShadow="0px 2px 5px rgba(52, 52, 52, 0.1)" borderRadius="10px"
                     p="16px">
                  <Flex alignItems="center" justifyContent="space-between" mb="28px">
                    <Text fontFamily="Roboto" fontWeight="700" fontSize="18px" color="#394A6C">Состав заказа</Text>
                    <Box onClick={() => navigate("/basket")} cursor="pointer"><SvgEditIcon/></Box>
                  </Flex>
                  {cart?.map((el, idx) => (
                    <Box key={idx}>
                      <Flex justifyContent="space-between" alignItems="start" mb="13px">
                        <Text fontFamily="Roboto" fontWeight="500" fontSize="16px" color="#4D5E80"
                              noOfLines={2}>{el.product.name}</Text>
                        <Flex pt="2px">
                          <Text fontFamily="Roboto" fontWeight="400" fontSize="13px" color="#000000"
                                opacity="0.5">{el.quantity}&nbsp;x&nbsp;</Text>
                          <Flex fontFamily="Roboto" fontWeight="400" fontSize="13px" color="#000000"
                                opacity="0.5">{el.product.price}&nbsp;руб.</Flex>
                        </Flex>
                      </Flex>
                      <Divider mb="10px"/>
                    </Box>
                  ))}
                </Box>
                <Box h="auto" w="full" bg="#FFFFFF" boxShadow="0px 2px 5px rgba(52, 52, 52, 0.1)" borderRadius="6px"
                     px={{base: "10px", md: "43px"}} pb={{base: "12px", md: "26px"}} pt={{base: "0", md: "13px"}}>
                  <Divider py={{base: "12px", md: "0"}}/>
                  <Flex pt={{base: "12px", md: "10px"}} alignItems="center" justifyContent="space-between">
                    <Text fontFamily="Roboto" fontWeight="700" fontSize="18px" color="#394A6C">Итого:</Text>
                    <Text fontFamily="Roboto" fontWeight="700" fontSize="18px" color="#394A6C">{totalPrice} руб.</Text>
                  </Flex>
                </Box>
              </Box>
            </Flex>
            <Box display={{lg: 'none', base: 'block'}}>
              <OrderingChecked/>
            </Box>
            <Flex alignItems="center">
              <Button
                isDisabled={!isValid && !dirty} formTarget="_blank"
                onClick={() => {
                  handleSubmit()
                  sendOrder(isValid && dirty, values)
                }}
                isLoading={isLoading}
                w={{base: "100%", lg: "49%"}}
                h="48px" borderRadius="5px"
                fontFamily="Roboto" fontWeight="500" fontSize="16px" color="#FFFFFF"
                _hover={{bg: '#1BC95CD2'}}
                bg="#1BC95C" p="16px 82px"
                type='submit' variant='none'>
                Оформить заказ
              </Button>

            </Flex>

          </Container>
        )}
      </Formik>
    </Box>
  );
};

export default Ordering;
