import {AppDispatch} from "../../../store/store";
import {categorySlice} from "./Category.slice";
import $api from "../../api/Api";
import {ICategories, RootCategory} from "../../../models/category";

export const getCategories = () =>async (dispatch: AppDispatch) => {
  try {
    dispatch(categorySlice.actions.getCategory())
    const response = await $api.get<RootCategory<ICategories>>('categories/')
    dispatch(categorySlice.actions.getCategorySuccess(response.data.results))
  }catch (e: any) {
    dispatch(categorySlice.actions.getCategoryError(e.response?.data?.message || e.detail))
    console.log(e)
  }
}