import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {setupListeners} from "@reduxjs/toolkit/query";
import {CategoryReducer} from "../components/Categories/Categories.Reducer/Category.slice";
import {QuestionReducer} from "../components/FooterForm/FormReducer/question.slice";
import {ProductsReducer} from "../pages/AllProducts/ProductReducer/productsSLice";
import {CartReducer} from "../pages/Basket/BasketReducer/basket.slice";
import {DetailReducer} from "../pages/DetailPage/DetailReducer/detail.slice";
import {SubCategoryReducer} from "../pages/SubCategory/SubCategoryReducer/subcategory.slice";
import {OrderingReducer} from "../pages/Ordering/OrderingReducer/ordering.slice";
import {productsApi} from "./services/search";
import {SubProductsReducer} from "../pages/AllProducts/ProductReducer/sub-products.slice";

const rootReducer = combineReducers({
  [productsApi.reducerPath]: productsApi.reducer,
  CategoryReducer,
  QuestionReducer,
  ProductsReducer,
  SubProductsReducer,
  CartReducer,
  DetailReducer,
  SubCategoryReducer,
  OrderingReducer,
})



const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(productsApi.middleware),
    devTools: false
  })
}
export const store = setupStore()
setupListeners(store.dispatch)


type AppStore = ReturnType<typeof setupStore>
export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = AppStore['dispatch']