import React from 'react';
import {Box, Text, Heading, Flex, UnorderedList, ListItem} from "@chakra-ui/react";
import TitleComponent from "../../../components/TitleComponent";
import {AiFillCaretRight} from "react-icons/ai"
import FooterForm from "../../../components/FooterForm";
import {advantagesData} from "../../../helpers/admin-data"


const AboutCompany = () => {
    return (
        <Box rounded='10px' bg={{md: 'white', base: 'transparent'}} py={{md: '19px', base: '28px'}}
             px={{md: '36px', base: '0'}}>
            <TitleComponent title={'О компании'} shadowTitle={'О нас'} visible={true}/>
            <Box w='full' fontFamily='Roboto' my={7} pr={{md: '6%', base: '0'}}>
                <Text
                    pr={{lg: '24%', base: '0'}}
                    fontWeight='400'
                    fontSize='16px'
                    color='#394A6C'
                    lineHeight={'30px'}
                >Компания Керемет уже не первый год является официальным дистрибьютором
                    известных производителей строительных и отделочных материалов на территории Российской
                    Федерации.</Text>
                <Text
                    fontWeight='400'
                    fontSize='16px'
                    color='#6B7A99'
                    lineHeight={'30px'}
                >С 2001 года компания Керемет успешно сотрудничает со строительными рынками Москвы и Московской области,
                    обеспечивая им оптовые поставки сухих смесей, тепло- и гидроизоляционных, а также других
                    стройматериалов. Такое сотрудничество является удобным и взаимовыгодным, и уже по праву оценено
                    нашими деловыми партнерами, регулярно обращающимися
                    к нам. Компания гарантирует своевременную доставку предлагаемой продукции.</Text>
                <Flex mt="73px" mb="25px" alignItems="center"><AiFillCaretRight fontSize="20px" color="#394A6C"/>
                    <Heading ml="13.18px" fontFamily="Roboto" fontWeight="400" fontSize="20px"
                             color="#394A6C">Преимущества</Heading></Flex>
                <UnorderedList fontFamily="Roboto" fontWeight="400" fontSize="16px" color="#6B7A99" lineHeight="30px">
                    {advantagesData.map(el => (
                        <ListItem key={el.item}>{el.item}</ListItem>
                    ))}
                </UnorderedList>
            </Box>
            <Box display={{md: 'none', base: 'block'}}>
                <FooterForm/>
            </Box>
        </Box>
    );
};

export default AboutCompany;