import React from 'react';

const LogoAdaptive = () => {
  return (
    <svg width="72" height="36" viewBox="0 0 72 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.3255 6.81392H19.9302C19.4165 6.81392 19 6.39744 19 5.88369V1.46511C19 1.20824 19.2082 1 19.4651 1H31.3255M31.3255 6.81392V1M31.3255 6.81392H41.7906C42.3043 6.81392 42.7208 6.39744 42.7208 5.88369V1.46511C42.7208 1.20824 42.5125 1 42.2557 1H31.3255M31.3255 17.9766H19.4651C19.2082 17.9766 19 17.7684 19 17.5115V13.3255C19 12.8118 19.4165 12.3953 19.9302 12.3953M31.3255 17.9766V12.3953M31.3255 17.9766H42.7208M42.7208 12.3953H31.3255M42.7208 12.3953V17.9766M42.7208 12.3953H51.7905M42.7208 12.3953V6.58136M42.7208 12.3953H38.4185M42.7208 12.3953H49.6975M31.3255 12.3953H19.9302M19.9302 12.3953H25.5116M19.9302 12.3953C19.4165 12.3953 19 11.9788 19 11.465V7.51159C19 6.99784 19.4165 6.58136 19.9302 6.58136H25.5116M42.7208 17.9766H52.023M25.5116 12.3953V6.58136M25.5116 12.3953H38.4185M25.5116 6.58136H38.4185M42.7208 6.58136H38.4185M42.7208 6.58136H49.6975M38.4185 12.3953V6.58136M49.6975 6.58136H52.023M49.6975 6.58136V12.3953M49.6975 12.3953H52.023" stroke="#C84428" strokeWidth="1.39534"/>
      <path d="M0 34.8824V21H1.71601V27.517L7.78955 21H9.94903L3.64412 27.7387L10.4407 34.8824H8.22337L1.71601 28.0954V34.8824H0Z" fill="url(#paint0_linear_403_2523)"/>
      <path d="M15.1412 35.1716C14.1193 35.1716 13.226 34.9466 12.4611 34.4967C11.7028 34.0404 11.1115 33.4074 10.6873 32.5976C10.2631 31.7813 10.051 30.8301 10.051 29.744C10.051 28.6064 10.2599 27.623 10.6776 26.794C11.0954 25.9584 11.677 25.3157 12.4226 24.8659C13.1745 24.4095 14.055 24.1814 15.0641 24.1814C16.1117 24.1814 17.0018 24.4224 17.7345 24.9044C18.4736 25.3864 19.0263 26.0741 19.3927 26.9675C19.7654 27.8608 19.9261 28.9245 19.8747 30.1585H18.1394V29.5415C18.1201 28.2625 17.8598 27.3081 17.3585 26.6783C16.8572 26.042 16.1117 25.7239 15.1219 25.7239C14.0615 25.7239 13.2549 26.0645 12.7022 26.7458C12.1494 27.427 11.8731 28.4039 11.8731 29.6765C11.8731 30.904 12.1494 31.8552 12.7022 32.5301C13.2549 33.2049 14.0422 33.5423 15.0641 33.5423C15.7518 33.5423 16.3495 33.3849 16.8572 33.0699C17.365 32.755 17.7634 32.3019 18.0526 31.7106L19.7012 32.2794C19.2963 33.1985 18.6857 33.9119 17.8695 34.4196C17.0597 34.9209 16.1502 35.1716 15.1412 35.1716ZM11.2946 30.1585V28.7799H18.9878V30.1585H11.2946Z" fill="url(#paint1_linear_403_2523)"/>
      <path d="M21.6355 34.8824V24.4706H23.1683V26.9868L22.9176 26.659C23.0398 26.3376 23.1972 26.042 23.39 25.7721C23.5893 25.5021 23.811 25.2804 24.0552 25.1069C24.3252 24.8883 24.6304 24.7212 24.9711 24.6056C25.3117 24.4899 25.6588 24.4224 26.0123 24.4031C26.3657 24.3774 26.6967 24.3999 27.0052 24.4706V26.0806C26.6453 25.9842 26.2501 25.9584 25.8194 26.0034C25.3888 26.0484 24.9904 26.2059 24.624 26.4758C24.2898 26.7136 24.0295 27.0028 23.8431 27.3435C23.6632 27.6841 23.5379 28.0537 23.4672 28.4521C23.3965 28.8442 23.3611 29.2459 23.3611 29.6572V34.8824H21.6355Z" fill="url(#paint2_linear_403_2523)"/>
      <path d="M32.7661 35.1716C31.7442 35.1716 30.8508 34.9466 30.086 34.4967C29.3276 34.0404 28.7363 33.4074 28.3121 32.5976C27.8879 31.7813 27.6759 30.8301 27.6759 29.744C27.6759 28.6064 27.8847 27.623 28.3025 26.794C28.7202 25.9584 29.3019 25.3157 30.0474 24.8659C30.7994 24.4095 31.6799 24.1814 32.6889 24.1814C33.7365 24.1814 34.6267 24.4224 35.3594 24.9044C36.0985 25.3864 36.6512 26.0741 37.0175 26.9675C37.3903 27.8608 37.551 28.9245 37.4996 30.1585H35.7643V29.5415C35.745 28.2625 35.4847 27.3081 34.9834 26.6783C34.4821 26.042 33.7365 25.7239 32.7468 25.7239C31.6863 25.7239 30.8797 26.0645 30.327 26.7458C29.7743 27.427 29.4979 28.4039 29.4979 29.6765C29.4979 30.904 29.7743 31.8552 30.327 32.5301C30.8797 33.2049 31.667 33.5423 32.6889 33.5423C33.3766 33.5423 33.9743 33.3849 34.4821 33.0699C34.9898 32.755 35.3883 32.3019 35.6775 31.7106L37.326 32.2794C36.9211 33.1985 36.3106 33.9119 35.4943 34.4196C34.6845 34.9209 33.7751 35.1716 32.7661 35.1716ZM28.9195 30.1585V28.7799H36.6126V30.1585H28.9195Z" fill="url(#paint3_linear_403_2523)"/>
      <path d="M51.4238 34.8824L51.4334 28.2208C51.4334 27.4431 51.2246 26.8357 50.8068 26.3987C50.3955 25.9552 49.8588 25.7335 49.1968 25.7335C48.8112 25.7335 48.4449 25.8235 48.0978 26.0034C47.7508 26.177 47.468 26.4533 47.2494 26.8325C47.0309 27.2053 46.9217 27.6841 46.9217 28.269L46.0155 27.9026C46.0026 27.1635 46.1536 26.5176 46.4686 25.9649C46.7899 25.4057 47.2269 24.9719 47.7797 24.6634C48.3324 24.3549 48.9558 24.2007 49.6499 24.2007C50.7361 24.2007 51.5909 24.5316 52.2143 25.1936C52.8377 25.8492 53.1494 26.7329 53.1494 27.8448L53.1398 34.8824H51.4238ZM38.9682 34.8824V24.4706H40.5011V27.2567H40.6939V34.8824H38.9682ZM45.2057 34.8824L45.2153 28.2882C45.2153 27.4913 45.0096 26.8679 44.5983 26.418C44.187 25.9617 43.6407 25.7335 42.9594 25.7335C42.2846 25.7335 41.7383 25.9649 41.3205 26.4276C40.9028 26.8904 40.6939 27.5041 40.6939 28.269L39.7877 27.7291C39.7877 27.0543 39.9483 26.4501 40.2697 25.9167C40.5911 25.3832 41.0281 24.9655 41.5808 24.6634C42.1335 24.3549 42.7602 24.2007 43.4607 24.2007C44.1613 24.2007 44.7718 24.3485 45.2924 24.6441C45.813 24.9398 46.2147 25.3639 46.4975 25.9167C46.7803 26.463 46.9217 27.1153 46.9217 27.8737L46.912 34.8824H45.2057Z" fill="url(#paint4_linear_403_2523)"/>
      <path d="M59.6925 35.1716C58.6706 35.1716 57.7772 34.9466 57.0124 34.4967C56.254 34.0404 55.6628 33.4074 55.2386 32.5976C54.8144 31.7813 54.6023 30.8301 54.6023 29.744C54.6023 28.6064 54.8112 27.623 55.2289 26.794C55.6467 25.9584 56.2283 25.3157 56.9739 24.8659C57.7258 24.4095 58.6063 24.1814 59.6154 24.1814C60.663 24.1814 61.5531 24.4224 62.2858 24.9044C63.0249 25.3864 63.5776 26.0741 63.944 26.9675C64.3167 27.8608 64.4774 28.9245 64.426 30.1585H62.6907V29.5415C62.6714 28.2625 62.4111 27.3081 61.9098 26.6783C61.4085 26.042 60.663 25.7239 59.6732 25.7239C58.6128 25.7239 57.8062 26.0645 57.2534 26.7458C56.7007 27.427 56.4244 28.4039 56.4244 29.6765C56.4244 30.904 56.7007 31.8552 57.2534 32.5301C57.8062 33.2049 58.5935 33.5423 59.6154 33.5423C60.3031 33.5423 60.9008 33.3849 61.4085 33.0699C61.9163 32.755 62.3147 32.3019 62.6039 31.7106L64.2525 32.2794C63.8476 33.1985 63.237 33.9119 62.4208 34.4196C61.611 34.9209 60.7015 35.1716 59.6925 35.1716ZM55.8459 30.1585V28.7799H63.5391V30.1585H55.8459Z" fill="url(#paint5_linear_403_2523)"/>
      <path d="M72 34.8824C71.383 35.0045 70.7724 35.0527 70.1683 35.027C69.5706 35.0077 69.0371 34.8888 68.568 34.6703C68.0988 34.4453 67.7421 34.0983 67.4979 33.6291C67.2922 33.2178 67.1797 32.8032 67.1605 32.3855C67.1476 31.9613 67.1412 31.4825 67.1412 30.949V21.5784H68.8572V30.8719C68.8572 31.2961 68.8604 31.6592 68.8668 31.9613C68.8797 32.2634 68.9472 32.5236 69.0693 32.7422C69.3006 33.1535 69.667 33.3945 70.1683 33.4652C70.676 33.5359 71.2866 33.5166 72 33.4074V34.8824ZM65.0299 25.8877V24.4706H72V25.8877H65.0299Z" fill="url(#paint6_linear_403_2523)"/>
      <defs>
        <linearGradient id="paint0_linear_403_2523" x1="35.8594" y1="21.6266" x2="35.8594" y2="35.8126" gradientUnits="userSpaceOnUse">
          <stop stopColor="#78C22B"/>
          <stop offset="1" stopColor="#3C8700"/>
        </linearGradient>
        <linearGradient id="paint1_linear_403_2523" x1="35.8594" y1="21.6266" x2="35.8594" y2="35.8126" gradientUnits="userSpaceOnUse">
          <stop stopColor="#78C22B"/>
          <stop offset="1" stopColor="#3C8700"/>
        </linearGradient>
        <linearGradient id="paint2_linear_403_2523" x1="35.8594" y1="21.6266" x2="35.8594" y2="35.8126" gradientUnits="userSpaceOnUse">
          <stop stopColor="#78C22B"/>
          <stop offset="1" stopColor="#3C8700"/>
        </linearGradient>
        <linearGradient id="paint3_linear_403_2523" x1="35.8594" y1="21.6266" x2="35.8594" y2="35.8126" gradientUnits="userSpaceOnUse">
          <stop stopColor="#78C22B"/>
          <stop offset="1" stopColor="#3C8700"/>
        </linearGradient>
        <linearGradient id="paint4_linear_403_2523" x1="35.8594" y1="21.6266" x2="35.8594" y2="35.8126" gradientUnits="userSpaceOnUse">
          <stop stopColor="#78C22B"/>
          <stop offset="1" stopColor="#3C8700"/>
        </linearGradient>
        <linearGradient id="paint5_linear_403_2523" x1="35.8594" y1="21.6266" x2="35.8594" y2="35.8126" gradientUnits="userSpaceOnUse">
          <stop stopColor="#78C22B"/>
          <stop offset="1" stopColor="#3C8700"/>
        </linearGradient>
        <linearGradient id="paint6_linear_403_2523" x1="35.8594" y1="21.6266" x2="35.8594" y2="35.8126" gradientUnits="userSpaceOnUse">
          <stop stopColor="#78C22B"/>
          <stop offset="1" stopColor="#3C8700"/>
        </linearGradient>
      </defs>
    </svg>

  );
};

export default LogoAdaptive;