import React, {useState} from 'react';
import {Box, Button, Flex, Heading, Image, Text, useToast} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import AddToBasketBtn from "../../../components/ui/AddToBasketBtn";
import {IProduct} from "../../../models/products";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {addToCart, StatusType} from "../../Basket/BasketReducer/basketActions";
import Loader from "../../../components/Loader";
import {creatorToast, ErrorToast, SuccessToast} from "../../../components/ui/ToastStatus";
import {NavigateToDetail} from "../../../helpers/path";
import {BsCheck} from "react-icons/bs";


interface Props {
  product: IProduct;
}

const SubProductCard = ({product}: Props) => {
  const toast = useToast()
  const dispatch = useAppDispatch()
  const {cart} = useAppSelector(state => state.CartReducer)
  const found = cart?.some(c => c.product.id === product.id)
  const [isLoading, setLoader] = useState<boolean>(false)
  const pendingToCart = () => setLoader(true)
  const successAdded = () => {
    toast(creatorToast(SuccessToast, 'success'))
    setLoader(false)
  }
  const errorAdded = () => {
    toast(creatorToast(ErrorToast, "error"))
    setLoader(false)
  }
  const statusFn = (status: StatusType) => {
    switch (status) {
      case 'pending':
        pendingToCart()
        return;
      case 'success':
        successAdded()
        return;
      case 'rejected':
        errorAdded()
        return;
    }
  }

  const addToBasket = (onOpen: () => void) => {
    dispatch(addToCart(product.id, 1, onOpen, statusFn))
  }


  return (
    <Flex
      w='full' h={{md: '140px', base: '120px'}} justifyContent='space-between' alignItems={{lg: 'center', base: 'auto'}}
      py={{md: 4, base: 2}}
      px={{md: '35px', sm: 2, base: 1}}
      borderBottom='1px solid rgba(38, 51, 77, 0.1)'>
      <Box h='full' w='137px' border='1px solid #6B7A9919' rounded='5px'>
        <Link to={NavigateToDetail(product)}>
          <Image src={product.main_image} width='100%' height='100%' alt='place for image' rounded='5px'/>
        </Link>
      </Box>
      <Flex
        justifyContent='space-between' height='100%' alignItems={{lg: 'center', base: 'start'}} ml={{sm: 6, base: 3}}
        w='80%' py={1}
        flexDirection={{lg: 'row', base: 'column'}} fontFamily='Roboto'>
        <Heading
          noOfLines={3}
          color='#6B7A99' fontWeight='500' fontSize={{md: '16px', base: '14px'}} w={{lg: '60%', base: '100%'}}
          fontFamily='Roboto'
        >{product.name}</Heading>

        <Flex alignItems='center' justifyContent='space-between' w={{lg: '35%', base: 'full'}}>
          <Text
            color='#6B7A99' fontWeight='900' fontSize='16px'
          >{product.price}&nbsp;руб.</Text>
          {found && <Button
              color='#FF3A10' fontWeight='500' fontSize='16px'
              bg='#E662460D' rounded='5px' px='10px' size='sm'
            ><BsCheck/></Button>}

          {!found && <AddToBasketBtn addToBasket={addToBasket}>
              <Button
                color='#FF3A10' fontWeight='500' fontSize='12px'
                bg='#E662460D' rounded='5px' px='10px' size='sm'
              >{isLoading ? <Loader/> : 'В корзину'}</Button>
            </AddToBasketBtn>}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SubProductCard;