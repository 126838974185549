import {AppDispatch} from "../../../store/store";
import {subcategorySlice} from "./subcategory.slice";
import $api from "../../../components/api/Api";
import {ISubCategory, RootCategory} from "../../../models/category";

export const getSubcategories = (id: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(subcategorySlice.actions.getSubcategory())
    const response = await $api.get<RootCategory<ISubCategory>>(`categories/${id}/`)
    dispatch(subcategorySlice.actions.getSubcategorySuccess(response.data.results))
  }catch (e: any) {
    console.log(e)
    dispatch(subcategorySlice.actions.getSubcategoryError(e.response?.data?.message || e.detail))
  }
}