import React from 'react';

const MoreArrowSvg = () => {
  return (
    <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.5 3L6.5 9L2.5 15" stroke="#E66246" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.5 3L9.5 9L5.5 15" stroke="#E66246" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};

export default MoreArrowSvg;