import React from 'react';
import {Box, Button, Flex, Text} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import EmptyBasketSvg from "../../assets/svg/EmptyBasketSvg";

const EmptyCart = () => {
  const navigate = useNavigate()
  return (
    <Flex
      mt={{md: '50px', base: 12}} w={{md: '90%', base: 'full'}}
      flexDirection={{md: 'row', base: 'column-reverse'}}
      alignItems='center' justifyContent='space-between'>
      <Flex
        flexDirection='column'
        alignItems={{md: "start", base: 'center'}}
        mr={{lg: '20%', base: '0'}}>
        <Text
          w={{md: '415px', base: 'full'}}
          fontFamily='Roboto'
          fontSize={{md: '18px', base: '16px'}}
          fontWeight='400'
          color='#394A6C'
          textAlign={{md: 'start', base: 'center'}}
        >В корзине нет товара. Перейдите к покупке, чтобы добавить товар.</Text>
        <Button
          onClick={() => navigate('/products')}
          mt={8}
          variant='none'
          boxShadow='0px 10px 30px rgba(255, 102, 51, 0.3)'
          rounded='6px' w={{md: '233px', base: '241px'}}
          bg={'#E66246'}
          padding='10px 20px'
          fontFamily='Roboto'
          fontSize='16px'
          fontWeight='900'
          color='white'
        >Каталог</Button>
      </Flex>
      <Box opacity='0.5' transform={{md :'scale(1.1)', base: 'scale(0.7)'}}><EmptyBasketSvg/></Box>
    </Flex>
  );
};

export default EmptyCart;