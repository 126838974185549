import React, {useState} from 'react';
import {Box, Flex, Heading, Image, Text} from "@chakra-ui/react";
import {IoArrowRedoSharp} from "react-icons/io5";
import NewProductsImg from '../../../assets/img/new-produts.jpg'
import HitProdaj from '../../../assets/img/hit-prodaj.jpg'
import RecommendImg from '../../../assets/img/recommend.jpg'
import {useNavigate} from "react-router-dom";
import {getSortingProducts} from "../../AllProducts/ProductReducer/ProductsActions";
import {NavigationBySub} from "../../../helpers/path";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {ICategories} from "../../../models/category";

const MainCategory = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isHoverNews, setHoverNews] = useState(false)
  const [isHoverPopular, setHoverPopular] = useState(false)
  const [isHoverRecommend, setHoverRecommend] = useState(false)

  return (
    <Flex flexDirection={{lg: 'row', md: 'column', sm: 'row', base: 'column'}}>

      <Flex w={{lg: '70%', md: '100%', sm: '70%', base: '100%'}} mr={{lg: 4, md: 0, sm: 4, base: 0}}
            mb={{lg: 0, md: 4, sm: 0, base: 4}}>


        <Box
          onClick={() => dispatch(getSortingProducts('Новинки', navigate))}
          onMouseOver={() => setHoverNews(true)}
          onMouseLeave={() => setHoverNews(false)}
          bg='#4B4E5B' rounded='10px' width='50%' position='relative' px={{md: 4, base: 2}} cursor='pointer'
          pt={{md: '26px', sm: '16px', base: '2px'}} pb={{md: '26px', sm: '16px', base: '10px'}}>
          <Flex flexDirection={{sm: 'column', base: 'column-reverse'}}>
            <Heading fontSize={{md: '22px', base: '18px'}} fontWeight='900' color='white' fontFamily='Roboto'
                     textAlign={{sm: 'center', base: 'start'}}>Новинки</Heading>
            <Flex
              position='absolute' right={{md: '12px', base: '8px'}} top={{sm: '15px', base: 'auto'}}
              bottom={{sm: 'auto', base: '10px'}} zIndex='2'
              justifyContent='center' alignItems='center' bg={isHoverNews ? '#00000066' :'#00000033'}
              rounded='50%' color='white' width='40px' height='40px' transition='.2s'
            > <IoArrowRedoSharp/> </Flex>
            <Box mx='auto' w='full' my={{md: 8, base: 2}} height={{md: '132px', sm: '110px', base: '90px'}}
                 overflow='hidden' rounded='10px'>
              <Image width={isHoverNews ? '90%' : {sm: 'auto', base: 'full'}}
                     height='100%' src={NewProductsImg} transform={isHoverNews ? 'scale(1.2)' : 'scale(1)'}
                     borderRadius={isHoverNews ? '20px' : {md: '500px', base: '10px'}}
                     transition='.3s' mx='auto'/>
            </Box>
          </Flex>
          <Text fontSize={{md: '15px', base: '14px'}} fontWeight='700' color='white' fontFamily='Roboto'
                textAlign={{sm: 'center', base: 'start'}} opacity='0.7'>Всё для дома</Text>
        </Box>


        <Box
          onClick={() => dispatch(getSortingProducts('Хит продаж', navigate))}
          onMouseOver={() => setHoverPopular(true)}
          onMouseLeave={() => setHoverPopular(false)}
          bg='#475572' rounded='10px' width='50%' position='relative' px={{md: 4, base: 2}} cursor='pointer'
          pt={{md: '26px', sm: '16px', base: '2px'}} pb={{md: '26px', sm: '16px', base: '10px'}}
          ml={{sm: 4, base: 3}}>
          <Flex flexDirection={{sm: 'column', base: 'column-reverse'}}>
            <Heading fontSize={{md: '22px', base: '18px'}} fontWeight='900' color='white' fontFamily='Roboto'
                     textAlign={{sm: 'center', base: 'start'}}>Хит продаж</Heading>
            <Flex
              position='absolute' right={{md: '12px', base: '8px'}} top={{sm: '15px', base: 'auto'}}
              bottom={{sm: 'auto', base: '10px'}} zIndex='2'
              justifyContent='center' alignItems='center' bg={isHoverPopular ? '#FFFFFF33': '#FFFFFF1A'}
              rounded='50%' color='white' width='40px' height='40px' transition='.2s'
            > <IoArrowRedoSharp/> </Flex>
            <Box mx='auto' w='full' my={{md: 8, base: 2}} height={{md: '132px', sm: '110px', base: '90px'}}
                 overflow='hidden' rounded='10px'>
              <Image
                width={isHoverPopular ? '90%' : {sm: 'auto', base: 'full'}} height='100%' src={HitProdaj}
                borderRadius={isHoverPopular ? '20px' : {md: '500px', base: '10px'}} transform={isHoverPopular ? 'scale(1.2)' : 'scale(1)'}
                transition='.3s' mx='auto'/>
            </Box>
          </Flex>
          <Text
            fontSize={{md: '15px', base: '14px'}} fontWeight='700' color='white' fontFamily='Roboto'
            textAlign={{sm: 'center', base: 'start'}} opacity='0.7'>Цементы, песок</Text>
        </Box>


      </Flex>


      <Box
        onClick={() => dispatch(getSortingProducts('Рекомендуемые', navigate))}
        onMouseOver={() => setHoverRecommend(true)}
        onMouseLeave={() => setHoverRecommend(false)}
        bg='#2C323D' rounded='10px' width={{lg: '35%', md: '100%', sm: '35%', base: '100%'}} position='relative' cursor='pointer'
        px={{md: 4, base: 2}} pb={{md: '26px', sm: '16px', base: '6px'}} pt={{md: '26px', sm: '16px', base: '14px'}}>
        <Heading fontSize={{md: '22px', base: '18px'}} fontWeight='900' color='white' fontFamily='Roboto'
                 textAlign={{sm: 'center', base: 'start'}}>Рекомендуемые</Heading>
        <Flex
          position='absolute' right={{md: '15px', base: '8px'}} top='12px' zIndex='2'
          justifyContent='center' alignItems='center' bg={isHoverRecommend ? '#FFFFFF33': '#FFFFFF1A'}
          rounded='50%' color='white' width='40px' height='40px' transition='.2s'
        > <IoArrowRedoSharp/> </Flex>
        <Flex flexDirection={{sm: 'column', base: 'column-reverse'}}>
          <Box mx='auto' w='full' my={{md: 8, base: 2}} height={{md: '132px', sm: '110px', base: '132px'}}
               overflow='hidden' rounded='10px'>
            <Image width={{lg: isHoverRecommend ? "90%" : 'auto' ,sm: 'auto', base: 'full'}} height='100%' src={RecommendImg}
                   borderRadius={{lg: isHoverRecommend ? '20px' : '500px' ,md: '500px', base: '10px'}}
                   transform={{lg: isHoverRecommend ? 'scale(1.2)' : 'scale(1)', base: 'scale(1)'}}
                   transition='.3s' mx='auto'/>
          </Box>
          <Text fontSize={{md: '15px', base: '14px'}} fontWeight='700' color='white' fontFamily='Roboto'
                textAlign={{sm: 'center', base: 'start'}} opacity='0.7'>Стройматериалы</Text>
        </Flex>
      </Box>


    </Flex>
  );
};

export default MainCategory;