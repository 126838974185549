import React from 'react';
import {
  Box,
  Container
} from "@chakra-ui/react";
import Categories from "../../components/Categories/Categories";
import SimpleSorting from "../../components/SimpleSorting/SimpleSorting";
import MainTools from "../../components/MainTools/MainTools";
import FooterForm from "../../components/FooterForm";
import {useLocation} from "react-router-dom";

interface Props {
  children: JSX.Element
}

const MenuFix = ({children}: Props) => {
  const {pathname} = useLocation()
  const inDetail = pathname.includes('detail')
  const printer = pathname === "/about-company" || pathname === "/delivery" || pathname === "/payment-method"
  return (
    <Container maxW='container.xl' display='flex' justifyContent='space-between' py='50px'>
      <Box w='265px' display={{md: 'block', base: 'none'}}>
        <Box display={printer ? 'none' : 'block'}>
          <Categories/>
          <Box display={inDetail ? 'none' : 'block'}>
            <SimpleSorting/>
          </Box>
          <MainTools/>
        </Box>
        <FooterForm/>
      </Box>
      <Box ml={{lg: '49px',md: '29px', base: '0'}} w={{md: '75%', base: 'full'}}>
        {children}
      </Box>
    </Container>
  );
};

export default MenuFix;