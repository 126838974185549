import React, {useEffect, useRef, useState} from 'react';
import {Box, Container, Divider, Flex, Image, Input, Text} from "@chakra-ui/react";
import {AiOutlineSearch} from "react-icons/ai";
import {useDebounce} from "../../../hooks/debounce";
import {AnimatePresence, motion} from "framer-motion";
import {useNavigate} from "react-router-dom";
import {useOutsideClick} from "@chakra-ui/react";
import {useSearchProductsQuery} from "../../../store/services/search";
import Loader from "../../Loader";
import {NavigateToDetail} from "../../../helpers/path";


const MotionBox = motion(Box);
const MotionText = motion(Text);

const containerVariants = {
  expanded: {
    maxHeight: '350px',
    minHeight: '55px',
    height: "auto",
  },
  collapsed: {
    height: "55px"
  },
}


const containerTransition = {type: 'spring', damping: 22, stiffness: 500, duration: 100};

const SearchBar = () => {

  const navigate = useNavigate()
  const [value, setValue] = useState('')
  const ref = useRef<any>(null)
  const debounced = useDebounce(value)
  const [dropDown, setDropDown] = useState(false)

  const expandContainer = () => {
    setDropDown(true)
  }
  const collapseContainer = () => {
    setDropDown(false)
    setValue("")
  }
  useOutsideClick({
    ref: ref,
    handler: () => collapseContainer(),
  })
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }


  const {isLoading, isError, data} = useSearchProductsQuery(debounced, {
    skip: debounced.trim().length < 2,
    refetchOnFocus: true,
  })

  useEffect(() => {
    setDropDown(!!debounced.trim().length)
  }, [debounced, data])

  return (
    <Box w={{md: '27vw', base: 'full'}} position='relative' height='40px'>
      <MotionBox
        display="flex"
        w="full"
        flexDir="column"
        animate={dropDown ? "expanded" : "collapsed"}
        variants={containerVariants}
        bg="#FFFFFF" p={dropDown ? 2 : 0}
        borderRadius="13px"
        top='0' left='0' zIndex='60'
        boxShadow={dropDown && '0px 15px 23px 5px rgba(0, 0, 0, 0.15)'}
        ref={ref}
        transition={containerTransition}
      >
        <Box
          w={{
            xl: dropDown ? '80%' : '100%',
            md: '100%',
            sm: dropDown ? '85%' : '100%',
            base: dropDown ? '80%' : '100%'
          }}
          transition='.3s' position='relative' height='100%'>
          <Input
            onChange={handleChange}
            variant='none'
            border='1px solid #E0E0E0'
            w='full'
            height='40px'
            bg={'#F7F8FA'}
            value={value}
            placeholder='Поиск...'
            fontFamily='Roboto'
            fontWeight='400' color='#6B7A99'
            fontSize='16px'
            _placeholder={{fontSize: '12px', fontWeight: '700', color: '#ADB8CC'}}
            onFocus={expandContainer}
          />
          <Box position='absolute' right='10px' height='full' top='0' fontSize='1.3rem' color='#C3CAD9'>
            <AiOutlineSearch style={{marginTop: '50%'}}/>
          </Box>
        </Box>

        <AnimatePresence>
          {dropDown && (
            <MotionText
              display={{xl: 'block', md: 'none', base: 'block'}}
              position='absolute' right={'10px'} height='full' top={{md: '15px', base: '18px'}}
              key="close-icon" fontFamily='Roboto' fontWeight='400'
              textAlign="end"
              cursor="pointer" fontSize={{md: '16px', base: '14px'}}
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              onClick={collapseContainer}
              transition={{duration: 0.2}}
            >Отмена</MotionText>
          )}
        </AnimatePresence>

        {dropDown && <Box
          className='unscroll'
          overflowY="auto" w="100%" h="100%" display="flex" flexDir="column"
          mt="22px" left='0' top='0' pb={2}
        >
          {
            <ul>
              {isLoading && <Loader w='10px' h='10px'/>}
              {!!data?.length && data?.map(product => (
                <Flex
                  onClick={() => navigate(NavigateToDetail(product))}
                  key={product.id} px={2} py={2}
                  alignItems='center'
                  borderTop="0.3px solid #4D4D4D3D"
                  cursor='pointer' _hover={{bg: '#e7e6e6'}}
                  fontFamily='Roboto' fontSize='12px' color='#6B7A99' fontWeight='500'
                >
                  <Image rounded='3px' mr={4} src={product.main_image} width={30}/>
                  {product.name}
                </Flex>
              ))}

              {!data?.length && value.length > 1 && <Box
                display="flex" justifyContent="center" alignItems="center" fontWeight="500"
                fontFamily='Roboto' color='#6B7A99' fontSize="1rem"
              >Не найден</Box>}
            </ul>
          }
        </Box>}

      </MotionBox>
    </Box>
  );
};

export default SearchBar;