import React from 'react';
import {Box, Container, Flex, Text} from "@chakra-ui/react";
import {headerNav} from "../navData";
import {Link} from "react-router-dom";
import LogoAdaptive from "../../../assets/svg/LogoAdaptive";

const Footer = () => {
  return (
    <Box bg='#F7F8FA' py={3}>
      <Container maxW={{md: 'container.xl', base: 'full'}} px={{md: '0', base: '5px'}}>
        <Flex justifyContent='space-between' alignItems='start'
              bg='#33466C' w={{md: '98%', base: '100%'}} rounded='8px' py="25px" px={{md: 10, base: 0}} mx='auto'>
          <Flex flexDirection={{lg: 'row', base: 'column'}} justifyContent='space-between'
                alignItems={{lg: 'center', base: 'start'}} w='full'>
            <Flex flexDirection={{md: 'row', base: 'column'}}>
              {headerNav?.map(link => (
                <Box key={link.path} fontFamily='Roboto' fontWeight='500' color='#FFFFFF' fontSize='14px' mx={4} my={2}>
                  <Link to={link.path}>{link.name}</Link>
                </Box>
              ))}
            </Flex>
            <Flex fontFamily='Roboto' fontWeight='300' color='#FFFFFF'
                  mt={{md: '0', base: '23px'}} fontSize='12px'
                  flexWrap={{md: 'nowrap', base: 'wrap'}}
            >
              <Text mx={4} my={2}>© 2024 «KEREMET». Все права защищены</Text>
              <Text mx={4} my={2}><a href="https://motion.kg/" target='_blank'>Разработал Motion Web LLC</a> </Text>
            </Flex>
          </Flex>

          <Box display={{lg: 'none', base: 'block'}} pr={4} pt={2}>
            <Link to={'/'}>
              <LogoAdaptive/>
            </Link>
          </Box>

        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;