import React, {useState} from 'react';
import {Box, Flex, Image, Text} from "@chakra-ui/react";
import ToolsJPG from "../../assets/img/tools-img.jpg"
import {BiRightArrowAlt} from "react-icons/bi";
import {NavigationBySub} from "../../helpers/path";
import {useNavigate} from "react-router-dom";
import {ICategories} from "../../models/category";
import {useAppSelector} from "../../hooks/redux";

const MainTools = () => {
  const navigate = useNavigate()
  const {categories} = useAppSelector(state => state.CategoryReducer)
  const tools: ICategories | undefined = categories.find(c => c.name.toLowerCase() === "инструменты")
  const [isHover, setIsHover] = useState(false)
  return (
    <Box
      onClick={() =>  navigate(NavigationBySub(tools?.name, tools?.id, "Все"))}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      rounded='10px' height={{md: 'auto', base: '230px'}}
      w='full' cursor='pointer'
      overflow='hidden'
      position='relative' mb='30px'>
      <Image src={ToolsJPG} alt='image' rounded='10px' transition='.5s' w='full'
             transform={{md: isHover ? 'scale(1.1)' : 'scale(1)', base: 'scale(1.1)'}}/>
      <Flex
        alignItems='end' justifyContent='space-between'
        px={4} py={4}
        bg='linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)'
        position='absolute' height='50%' w='full' bottom='0' left='0'
      >
        <Text fontWeight='700' color='white' fontSize={{md: '16px', base: '22px'}} fontFamily='Roboto'>Инструменты</Text>
        <Flex justifyContent='center' alignItems='center' rounded='50%'
              border='1.8px solid #FFFFFF' fontSize={{md :"1rem", base: '1.3rem'}} width={{md :'20px', base: '30px'}} height={{md :'20px', base: '30px'}} color='white'>
          <BiRightArrowAlt/>
        </Flex>
      </Flex>
    </Box>
  );
};

export default MainTools;