const DefaultSort = 'По умолчанию'
const PopularSort = 'Хит продаж'
const NewProductsSort = 'Новинки'
const PriceDownSort = 'Цены по убыванию'
const PriceUpSort = 'Цены по возрастанию'
const AllProductSort = 'Все товары'
const RecommendSort = 'Рекомендуемые'

export type SortingTypes =
  typeof DefaultSort |
  typeof PopularSort |
  typeof NewProductsSort |
  typeof PriceDownSort |
  typeof PriceUpSort |
  typeof AllProductSort |
  typeof RecommendSort


export const sortingData: SortingTypes[] = ['По умолчанию', 'Хит продаж', 'Новинки', 'Цены по убыванию', 'Цены по возрастанию', 'Все товары', 'Рекомендуемые']