import React from 'react';

const NavBarHome = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5601 6.07749C17.2508 5.81401 16.7494 5.81401 16.4401 6.07749L7.40717 13.7722C6.96109 14.1522 6.7041 14.7087 6.7041 15.2947V24.0959C6.7041 25.2137 7.76787 26.1199 9.0801 26.1199H24.9201C26.2323 26.1199 27.2961 25.2137 27.2961 24.0959V15.2947C27.2961 14.7087 27.0391 14.1522 26.593 13.7722L17.5601 6.07749Z" stroke="#616161" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
    </svg>

  );
};

export default NavBarHome;