import React, {useEffect} from 'react';
import {Route, Routes, useLocation} from "react-router-dom";
import Home from "./pages/Home/Home";
import Basket from "./pages/Basket/Basket";
import Delivery from "./pages/Info/Delivery";
import PaymentMethod from "./pages/Info/PaymentMethod";
import SubCategory from "./pages/SubCategory/SubCategory";
import DetailPage from "./pages/DetailPage/DetailPage";
import Ordering from "./pages/Ordering/Ordering";
import {Box} from "@chakra-ui/react";
import MenuFix from "./pages/MenuFix/MenuFix";
import AboutCompany from "./pages/Info/AboutCompany";
import MobileCategories from "./components/Categories/MobileCategories";
import Info from "./pages/Info/Info";
import ErrorPage from "./components/404/ErrorPage";
import AllProducts from "./pages/AllProducts/AllProducts";
import {useAppDispatch, useAppSelector} from "./hooks/redux";
import {getCategories} from "./components/Categories/Categories.Reducer/categoryActions";
import {getALlProducts} from "./pages/AllProducts/ProductReducer/ProductsActions";


const App = () => {
  const {pathname} = useLocation()
  const {page} = useAppSelector(state => state.ProductsReducer)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getCategories())
  }, [])

  useEffect(() => {
    dispatch(getALlProducts(page))
  }, [page])

  useEffect(() => {
    window.scroll(0, 0)
  }, [pathname])
  return (
    <Box className='App' pt={{md: '72px', base: '25px'}}>
      <Routes>
        <Route
          path={'/'}
          element={
            <MenuFix>
              <Home/>
            </MenuFix>
          }
        />
        <Route
          path={'/products-list/:slug/:slugId/:sub'}
          element={
            <MenuFix>
              <SubCategory/>
            </MenuFix>
          }
        />

        <Route
          path={'/products'}
          element={
            <MenuFix>
              <AllProducts/>
            </MenuFix>
          }
        />

        <Route
          path={'/category'}
          element={<MobileCategories/>}
        />

        <Route
          path={'/basket'}
          element={<Basket/>}
        />
        <Route
          path={'/ordering'}
          element={<Ordering/>}
        />
        <Route
          path={'/product-/:slug/:slugId/:sub/:ID/:productName'}
          element={
            <MenuFix>
              <DetailPage/>
            </MenuFix>
          }
        />
        <Route
          path={'/delivery'}
          element={
            <MenuFix>
              <Delivery/>
            </MenuFix>
          }
        />
        <Route
          path={'/about-company'}
          element={
            <MenuFix>
              <AboutCompany/>
            </MenuFix>
          }
        />
        <Route
          path={'/payment-method'}
          element={
            <MenuFix>
              <PaymentMethod/>
            </MenuFix>
          }
        />
        <Route
          path={'/info'}
          element={
            <Info/>
          }
        />

        <Route path={'*'}
               element={<ErrorPage/>}
        />

      </Routes>
    </Box>
  );
};

export default App;