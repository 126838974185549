import React from 'react';
import {Box, Button, Flex, Text} from "@chakra-ui/react";
import {headerNav} from "../../components/Navigation/navData";
import {IoIosArrowForward} from "react-icons/io";
import {useNavigate} from "react-router-dom";
import FooterForm from "../../components/FooterForm";

const Info = () => {
  const navigate = useNavigate()
  return (
    <Box pt={20} px={4} display={{md: 'none', base: 'block'}}>
      <Box>
        {headerNav.filter(el => el.path !== '/')?.map(link => (
          <Button
            onClick={() => navigate(link.path)} key={link.path}
            h='71px' w='full' rounded='14px' bg='#394A6C'
            px={4} py={6} my='6px' display='flex'
            justifyContent='space-between' alignItems='center'>
            <Text color='#FFFFFF' fontFamily='Roboto' fontWeight='900' fontSize='18px'>{link.name}</Text>
            <Box color='#EDEEF0'><IoIosArrowForward/></Box>
          </Button>
        ))}
      </Box>

      <FooterForm/>
    </Box>
  );
};

export default Info;