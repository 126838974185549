import React from 'react';
import {Box, Container, Flex} from "@chakra-ui/react";
import Logo from "../../../assets/svg/Logo";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {MdShoppingCart} from "react-icons/md";
import SearchBar from "./SearchBar";
import {headerNav} from "../navData";
import {useAppSelector} from "../../../hooks/redux";


const Header = () => {
  const navigate = useNavigate()
  const {cart} = useAppSelector(state => state.CartReducer)
  const {pathname} = useLocation()
  return (
    <Box bg='white' borderBottom='2px solid #E3E5E8' position='fixed' left='0' top='-5px' w='full' zIndex='50'>
      <Container maxW='container.xl' pt={5} pb={4} display={{md: 'flex', base: 'none'}} justifyContent='space-between'
                 alignItems='center'>
        <Flex alignItems='center'>
          <Link to={'/'}>
            <Logo/>
          </Link>
          <Flex ml={{lg: 12, base: 6}}>
            {headerNav?.map(link => (
              <Box key={link.path} fontFamily='Roboto' fontWeight='500' color='#6B7A99' fontSize='14px'
                   mx={{lg: 4, base: 2}}>
                <Link to={link.path}>{link.name}</Link>
              </Box>
            ))}
          </Flex>
        </Flex>
        <Flex height='40px'>
          <SearchBar/>
          <Flex
            onClick={() => navigate('/basket')}
            justifyContent='center' cursor='grab' alignItems='center'
            height='full' width='40px' fontSize='1.4rem' color={pathname !== '/basket' ? '#C3CAD9' : '#E66246'}
            bg={'#F7F8FA'} border='1px solid #E0E0E0' rounded='8px' ml={{lg: 10, base: 2}} position='relative'>
            <MdShoppingCart/>
            { !!cart?.length && <Flex
              justifyContent='center' alignItems='center'
              w='18px' h='18px'
              bg='#F53025'
              position='absolute' top='-8px' right='-10px'
              rounded='50%' color='white'
              fontFamily='Roboto' fontWeight="500" fontSize='11px'
            >{cart?.length}</Flex>}
          </Flex>
        </Flex>
      </Container>

      <Container maxW='container.xl' pt={5} pb={4} display={{md: 'none', base: 'block'}}>
        <Flex height='40px'>
          <SearchBar/>
        </Flex>
      </Container>
    </Box>
  );
};

export default Header;