import React, {useRef} from 'react';
import {Box, Flex, Grid, GridItem, Text, Center, Wrap, WrapItem, Input, useToast} from "@chakra-ui/react";
import TitleComponent from "../../../components/TitleComponent";
import {HiOutlineArrowNarrowRight} from "react-icons/hi"
import FooterForm from "../../../components/FooterForm";
import {IoCopyOutline} from "react-icons/io5";
import {creatorToast, TextCopiedToast} from "../../../components/ui/ToastStatus";
// способ оплаты
const paymentData = [
  {item: "Юридические лица"},
  {item: "Физические лица"},
  {item: "наличный расчет"},
]
const propsData = [
  {item: "безналичный расчёт"},
  {item: "оплата банковскими картами"},
  {item: "электронная валюта"},
]

const bankData = [
  {id: 1, item: "QIWI:", props: "9265123397"},
  {id: 2, item: "Сбербанк:", props: "5469380107294745"},
]

const PaymentMethod = () => {
  const toast = useToast()
  const handleOnCopy = (e: any) => {
    e.target.select()
    document.execCommand("copy");
    toast(creatorToast(TextCopiedToast, 'success'))
  };

  return (
    <Box rounded='10px' mt={{base: "5px", md: "0"}} bg={{md: 'white', base: 'transparent'}} py={{md: '19px', base: '0'}}
         px={{md: '36px', base: '0'}}>
      <Box display={{md: 'block', base: 'none'}}><TitleComponent title={'Способы оплаты'} shadowTitle='Оплата'/></Box>
      <Box display={{md: 'none', base: 'block'}} py={8}><TitleComponent title={'Способы оплаты'} shadowTitle='Оплата'
                                                                        visible={true}/></Box>
      <Box w='full' fontFamily='Roboto' my={{md: 5, base: 2}}>
        <Flex flexDir={{base: "column", md: "row"}}>
          <Box>{paymentData.map(el => (
            <Flex key={el.item} alignItems="center" w="full" mb="20px">
              <Flex alignItems="center" w="20px" pos="relative">
                <HiOutlineArrowNarrowRight color="#003373"/>
                <Box pos="absolute" right="2px" top="0" w="9.05px" h="16.67px" bg="#003373"
                     opacity="0.4" borderRadius="0 100% 100% 0 / 0 50% 50% 0"/>
              </Flex>
              <Text ml="7.7" color="#6B7A99" fontFamily="Roboto" fontWeight="400" fontSize="16px"
                    textTransform="uppercase"
              >{el.item}</Text>
            </Flex>
          ))}</Box>
          <Box ml={{base: "0", md: "132px"}}>{propsData.map(el => (
            <Flex key={el.item} alignItems="center" w="full" mb="20px">
              <Flex alignItems="center" w="20px" pos="relative">
                <HiOutlineArrowNarrowRight color="#003373"/>
                <Box pos="absolute" right="2px" top="0" w="9.05px" h="16.67px" bg="#003373"
                     opacity="0.4" borderRadius="0 100% 100% 0 / 0 50% 50% 0"/>
              </Flex>
              <Text ml="7.7" color="#6B7A99" fontFamily="Roboto" fontWeight="400" fontSize="16px"
                    textTransform="uppercase"
              >{el.item}</Text>
            </Flex>
          ))}</Box>
        </Flex>
        <Flex flexDir={{base: "column", md: "row"}} mt={{base: "40px", md: "49px"}}>
          {bankData.map(el => (
            <Box key={el.id} position='relative'
                 mb={{base: "27px", md: "0"}} w={{base: "full", md: "190px"}}
                 ml={{base: "0", md: el.id === 2 ? "132px" : "0"}}>
              <Text onCopyCapture={handleOnCopy} pb="7px" color="#394A6C" fontFamily="Roboto" fontWeight="400"
                    fontSize="12px">{el.item}</Text>
              <Input
                value={el.props}  isReadOnly={true}
                onClick={e => handleOnCopy(e)}
                border="2px solid rgba(38, 51, 77, 0.1)" _hover={{bg: 'rgba(139,201,100, 0.3)'}}
                color="#394A6C" p="27px 10px" cursor='grab' textAlign='start'
                fontFamily="Roboto" fontWeight="400" fontSize="16px" borderRadius="10px"/>
              <Box position='absolute' zIndex='0' right='10px' bottom='23%' color="#394A6C">
                <IoCopyOutline/>
              </Box>
            </Box>
          ))}
        </Flex>
      </Box>
      <Box display={{md: 'none', base: 'block'}}>
        <FooterForm/>
      </Box>
    </Box>
  );
};

export default PaymentMethod;