import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const isPickUpType = 'Самовывоз'
const isDeliveryType = 'Доставка'

export type TypeOnDelivery = typeof isDeliveryType | typeof isPickUpType


interface OrderingState {
  deliveryType: TypeOnDelivery;
  paymentOnDelivery: boolean;
  isLoading: boolean

}

const initialState: OrderingState = {
  deliveryType: 'Доставка',
  paymentOnDelivery: true,
  isLoading: false
}

export const orderingSlice = createSlice({
  name: 'ordering',
  initialState,
  reducers: {
    getDeliveryType(state, {payload}) {
      if (payload.checked) {
        state.deliveryType = payload.name
      }
    },
    getCheckedValue(state, {payload}: PayloadAction<boolean>) {
      state.paymentOnDelivery = payload
    },

    addOrder(state){
      state.isLoading = true
    },
    addedOrder(state){
      state.isLoading = false
    }
  }
})

export const OrderingReducer = orderingSlice.reducer
export const {getDeliveryType, getCheckedValue} = orderingSlice.actions