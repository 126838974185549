import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IProduct, RootProducts} from "../../../models/products";
import {SortingTypes} from "../../../components/SimpleSorting/sort";

interface ProductsState {
  products: IProduct[];
  results: IProduct[];
  page: number;
  count: number;
  all_count: number;
  isError: string;
  isLoading: boolean;
  type_products: string
}

export const TYPE_ALL_PRODUCTS = 'Все товары'
const initialState: ProductsState = {
  products: [],
  results: [],
  page: 1,
  count: 0,
  all_count: 0,
  isError: '',
  isLoading: false,
  type_products: TYPE_ALL_PRODUCTS
}


export const subProductsSlice = createSlice({
  name: 'products-by-slug',
  initialState,
  reducers: {
    getProductsBySlug(state) {
      state.isLoading = true;
    },

    getCurrentPage(state, action: PayloadAction<number>) {
      state.page = action.payload
    },
    getProductsBySlugSuccess(state, {payload}: PayloadAction<RootProducts>) {
      state.isLoading = false;
      state.products = payload.results
      state.results = payload.results
      state.count = Math.ceil(payload.count / 20)
      state.all_count = payload.count
      if(payload.count <= 20){
        state.page = 1
      }
    },

    soringSlugProducts(state, action: PayloadAction<SortingTypes>) {
      switch (action.payload) {
        case "Новинки":
          state.results = state.products.filter(p => p.novelties)
          break;
        case "Рекомендуемые":
          state.results = state.products.filter(p => p.recommended)
          break;
        case "Хит продаж":
          state.results = state.products.filter(p => p.bestseller)
          break;
        case "Цены по возрастанию":
          state.results = [...state.products].sort((a, b) => +a.price - +b.price)
          break;
        case "Цены по убыванию":
          state.results = [...state.products].sort((a, b) => +b.price - +a.price)
          break;
        default : {
          state.results = state.products
          break;
        }
      }
      state.isLoading = false;
    },
    getProductsBySlugError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.products = [];
      state.isError = action.payload;
    },
  }
})

export const SubProductsReducer = subProductsSlice.reducer