import {AppDispatch} from "../../../store/store";
import {questionsSlice} from "./question.slice";
import $api from "../../api/Api";
import {creatorToast, ErrorPostDataToast, SuccessPostDataToast} from "../../ui/ToastStatus";

export const postQuestion = (data: any, toast: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(questionsSlice.actions.postQuestion())
    const res = await $api.post('questions', data)
    dispatch(questionsSlice.actions.postQuestionSuccess())
    toast(creatorToast(SuccessPostDataToast, 'success'))
  }catch (e: any) {
    console.log(e)
    dispatch(questionsSlice.actions.postQuestionError(e.response?.data?.message))
    toast(creatorToast(ErrorPostDataToast, 'error'))
  }
}