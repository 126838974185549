import React from 'react';
import {Formik} from 'formik'
import * as yup from 'yup'
import {Box, Button, Flex, Heading, Input, Link, Text, useToast} from "@chakra-ui/react";
import {ADDRESS, EMAIL_ADDRESS, PHONE_NUMBER1, SCHEDULE} from "../../helpers/admin-data";
import {IoLocationSharp} from "react-icons/io5";
import {RiSendPlaneFill} from "react-icons/ri";
import {MdEmail, MdPhone} from "react-icons/md";
import {AiFillClockCircle} from "react-icons/ai";
import {FaUserCircle} from "react-icons/fa";
import {postQuestion} from "./FormReducer/questionsActions";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import Loader from "../Loader";
import NavigateSvg from "../../assets/svg/NavigateSvg";

const dataAboutUs = [
  {
    title: ADDRESS,
    icon: <IoLocationSharp/>,
  },
  {
    title: SCHEDULE,
    icon: <AiFillClockCircle/>,
  },
]

const FooterForm = () => {
  const dispatch = useAppDispatch()
  const toast = useToast()
  const {isError, isLoading} = useAppSelector(s => s.QuestionReducer)
  const validationSchema = yup.object().shape({
    phone: yup.number().typeError("Должно быть числом").required('Обязательно'),
    username: yup.string().required('Обязательно'),
  })
  const postFormData = (data: {phone: string, username: string}) => {
    dispatch(postQuestion(data, toast))
  }

  return (
    <Box w='full' mt={{md: '0', base: '70px'}} mb={{md: '0', base: '60px'}}>
      <Flex flexDirection='column' alignItems='start' w='full' rounded='10px' bg='white' fontFamily='Roboto'
            padding='30px 20px'>
        <Heading fontWeight='700' color='#4D5E80' fontSize='14px' textAlign='center' w='full' mb={2}>Данные о
          нас</Heading>

        {
          dataAboutUs.map(data => (
            <Flex alignItems='start' my={2} key={data.title}>
              <Box color='#C3CAD9' fontSize='1.4rem'>{data.icon}</Box>
              <Text ml={4} lineHeight='25px' fontWeight='500' fontSize='12px' color='#6B7A99'>{data.title}</Text>
            </Flex>
          ))
        }
        

       <Flex alignItems='start' my={2}>
              <Box color='#C3CAD9' fontSize='1.4rem'><MdEmail/></Box>
              <Link href={`mailto:${EMAIL_ADDRESS}`} ml={4} lineHeight='25px' fontWeight='500' fontSize='12px' color='#6B7A99'>{EMAIL_ADDRESS}</Link>
        </Flex>
        <Flex alignItems='start' my={2}>
              <Box color='#C3CAD9' fontSize='1.4rem'><MdPhone/></Box>
              <Link href={`tel:${PHONE_NUMBER1}`} ml={4} className="zphone" lineHeight='25px' fontWeight='500' fontSize='12px' color='#6B7A99'>{PHONE_NUMBER1}</Link>
        </Flex>
      </Flex>

      <Formik
        initialValues={{
          phone:'', username: '',
        }}
        validateOnBlur
        onSubmit={postFormData}
        validationSchema={validationSchema}
      >
        {({values,handleBlur, handleChange, isValid, handleSubmit, errors, touched, dirty}) => (
          <Flex flexDirection='column' alignItems='start' w='full' fontFamily='Roboto' my='30px'>
            <Heading fontWeight='700' color='#4D5E80' fontSize='20px' textAlign='center' w='full' mb={2}>Оставить
              заявку</Heading>
            <Text px={6} my={2} fontWeight='700' color='#4D5E80' fontSize='12px'>Телефон</Text>
            <Box w='full' mb={4} position='relative'>
              <Input
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                type='tel' name='phone'
                placeholder='+7 (916) 789-987'
                boxShadow='0px 2px 5px rgba(54, 61, 77, 0.03)'
                rounded='30px'
                py={{md: 4, base: 6}} px={6}
                bg='white'
                w='full' color='#4D5E80'
                border='none'
                fontWeight='400'
                fontSize='16px'
                _placeholder={{color: '#7D8FB333', fontSize: '12px', fontWeight: '500'}}
              />
              <Box color='#C3CAD9' fontSize='1.3rem' px={4} position='absolute' right='0' zIndex='2'  top={{md :'0', base :'3px'}} height='full'><MdPhone style={{marginTop: '50%'}}/></Box>
              {errors.phone && touched.phone && <Text color='red.500' fontWeight='700' fontSize='12px' px={6} mt={1}>{errors.phone}</Text>}
            </Box>

            <Text px={6} my={2} fontWeight='700' color='#4D5E80' fontSize='12px'>Имя</Text>
            <Box w='full' mb={4} position='relative'>
              <Input
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.username}
                type='text' name='username'
                placeholder='Введите имя'
                boxShadow='0px 2px 5px rgba(54, 61, 77, 0.03)'
                rounded='30px'
                py={{md: 4, base: 6}} px={6}
                bg='white'
                w='full' color='#4D5E80'
                border='none'
                fontWeight='400'
                fontSize='16px'
                _placeholder={{color: '#7D8FB333', fontSize: '12px', fontWeight: '500'}}
              />
              <Box color='#C3CAD9' fontSize='1.3rem' px={4} position='absolute' right='0' zIndex='2'  top={{md :'0', base :'3px'}} height='full'><FaUserCircle style={{marginTop: '50%'}}/></Box>
              {errors.username && touched.username && <Text color='red.500' fontWeight='700' fontSize='12px' px={6} mt={1}>{errors.username}</Text>}
            </Box>

            <Button
              isDisabled={!isValid && !dirty}
              onClick={() => handleSubmit()}
              type='submit' _hover={{bg: '#E66246E6'}}
              variant='link'
              mt={4} py={4}
              rounded='30px'
              bg='#E66246' w='full'
              color='#FFFFFF' fontSize={{md: '14px', base: '16px'}} fontFamily='Roboto'
            >{isLoading ? <Loader/> : 'Отправить'}</Button>
          </Flex>
        )}
      </Formik>

    </Box>
  );
};

export default FooterForm;