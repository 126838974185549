import React, {useEffect, useState} from 'react';
import {
  Flex,
  Image,
  Text, useToast
} from "@chakra-ui/react";
import {MdShoppingBasket} from "react-icons/md";
import {Link} from "react-router-dom";
import AddToBasketBtn from "../ui/AddToBasketBtn";
import {IProduct} from "../../models/products";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {addToCart, StatusType} from "../../pages/Basket/BasketReducer/basketActions";
import Loader from "../Loader";
import {creatorToast, ErrorToast, SuccessToast} from "../ui/ToastStatus";
import {NavigateToDetail} from "../../helpers/path";
import {BsCheck} from "react-icons/bs";

interface Props {
  product: IProduct;
}

const ProductCard = ({product}: Props) => {
  const dispatch = useAppDispatch()
  const toast = useToast()
  const [isLoading, setLoader] = useState<boolean>(false)
  const {cart} = useAppSelector(state => state.CartReducer)
  const found = cart?.some(c => c.product.id === product.id)
  const pendingToCart = () => setLoader(true)
  const successAdded = () => {
    toast(creatorToast(SuccessToast, 'success'))
    setLoader(false)
  }
  const errorAdded = () => {
    toast(creatorToast(ErrorToast, "error"))
    setLoader(false)
  }

  const statusFn = (status: StatusType) => {
    switch (status) {
      case 'pending':
        pendingToCart()
        return;
      case 'success':
        successAdded()
        return;
      case 'rejected':
        errorAdded()
        return;
    }
  }


  const addToBasket = (onOpen: () => void) => {
    dispatch(addToCart(product.id, 1, onOpen, statusFn))
  }
  return (
    <Flex
      bg='white'
      flexDirection='column'
      height={{lg: '330px', bd: '300px', sm: '280px', base: '260px'}}
      boxShadow='0px 2px 5px rgba(54, 61, 77, 0.05)'
      rounded='5px'
      overflow='hidden'
    >
      <Link to={NavigateToDetail(product)}>
        <Image src={product.main_image} width='100%' height={{md: '200px', base: '150px'}}/>
      </Link>
      <Flex
        px={2}
        py={{md: 3, base: 2}}
        flexDirection='column' h='50%'
        justifyContent='space-between'
      >
        <Text
          textAlign='center'
          fontSize='12px' fontWeight='500' color='#394A6C' fontFamily='Roboto' lineHeight={{md: '20px', base: '15px'}}
        >{product.name}</Text>
        <Flex
          justifyContent='space-between' alignItems='center' mt={4}
        >
          <Text
            fontSize={{md: '12px', base: '14px'}} fontWeight='800' color='#6B7A99' fontFamily='Roboto'
          >{product.price} руб.</Text>


          {!found ? <AddToBasketBtn addToBasket={addToBasket}>
              <Flex
                justifyContent='center' alignItems='center' fontSize='1.2rem' cursor='pointer'
                width={{md: '40px', base: '36px'}} height={{md: '40px', base: '36px'}}
                rounded='50%' bg='#E66246' color='white' boxShadow='0px 2px 10px rgba(255, 102, 51, 0.25)'
              >{isLoading ? <Loader/> : <MdShoppingBasket/>}
              </Flex>
            </AddToBasketBtn>
            :
            <Flex
              justifyContent='center' alignItems='center' fontSize='2rem' cursor='pointer'
              width={{md: '40px', base: '36px'}} height={{md: '40px', base: '36px'}}
              rounded='50%' bg='#E66246' color='white' boxShadow='0px 2px 10px rgba(255, 102, 51, 0.25)'
            ><BsCheck/>
            </Flex>}

        </Flex>
      </Flex>
    </Flex>
  );
};
export default ProductCard;