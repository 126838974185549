import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IProduct, RootProducts} from "../../../models/products";
import {SortingTypes} from "../../../components/SimpleSorting/sort";

interface ProductsState {
  products: IProduct[];
  results: IProduct[];
  productsById: IProduct[];
  subProducts: IProduct[];
  page: number;
  count: number;
  all_count: number;
  isError: string;
  isLoading: boolean;
  type_products: string
}

export const TYPE_ALL_PRODUCTS = 'Все товары'
const initialState: ProductsState = {
  products: [],
  results: [],
  page: 1,
  count: 0,
  all_count: 0,
  subProducts: [],
  productsById: [],
  isError: '',
  isLoading: false,
  type_products: TYPE_ALL_PRODUCTS
}


export const productsSlice = createSlice({
  name: 'all-products',
  initialState,
  reducers: {
    getProducts(state) {
      state.isLoading = true;
    },
    getProductsSuccess(state, {payload}: PayloadAction<RootProducts>) {
      state.isLoading = false;
      state.isError = '';
      if (state.page > 1) {
        state.products = [...state.products, ...payload.results];
        state.results = [...state.results, ...payload.results];
      } else {
        state.products = payload.results;
        state.results = payload.results;
      }
      state.count = Math.ceil(payload.count / 20)
      state.all_count = payload.count
      state.type_products = TYPE_ALL_PRODUCTS
    },
    getCurrentPage(state, action: PayloadAction<number>) {
      state.page = action.payload
    },
    // getProductsBySlug(state, {payload}: PayloadAction<RootProducts>) {
    //   state.isLoading = false;
    //   state.subProducts = payload.results
    //   state.productsById = payload.results
    //   state.count = Math.ceil(payload.count / 20)
    //   state.all_count = payload.count
    // },
    soringAllProducts(state, action: PayloadAction<SortingTypes>) {
      switch (action.payload) {
        case "Новинки":
          state.results = state.products.filter(p => p.novelties)
          state.type_products = action.payload
          break;
        case "Рекомендуемые":
          state.results = state.products.filter(p => p.recommended)
          state.type_products = action.payload
          break;
        case "Хит продаж":
          state.results = state.products.filter(p => p.bestseller)
          state.type_products = action.payload
          break;
        case "Цены по возрастанию":
          state.results = [...state.products].sort((a, b) => +a.price - +b.price)
          state.type_products = action.payload
          break;
        case "Цены по убыванию":
          state.results = [...state.products].sort((a, b) => +b.price - +a.price)
          state.type_products = action.payload
          break;
        default : {
          state.results = state.products
          state.type_products = TYPE_ALL_PRODUCTS
          break;
        }
      }
      state.isLoading = false;
    },
    // soringIdProducts(state, action: PayloadAction<SortingTypes>) {
    //   switch (action.payload) {
    //     case "Новинки":
    //       state.productsById = state.subProducts.filter(p => p.novelties)
    //       break;
    //     case "Рекомендуемые":
    //       state.productsById = state.subProducts.filter(p => p.recommended)
    //       break;
    //     case "Хит продаж":
    //       state.productsById = state.subProducts.filter(p => p.bestseller)
    //       break;
    //     case "Цены по возрастанию":
    //       state.productsById = [...state.subProducts].sort((a, b) => +a.price - +b.price)
    //       break;
    //     case "Цены по убыванию":
    //       state.productsById = [...state.subProducts].sort((a, b) => +b.price - +a.price)
    //       break;
    //     default : {
    //       state.productsById = state.subProducts
    //       break;
    //     }
    //   }
    //   state.isLoading = false;
    // },
    getProductsError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.products = [];
      state.isError = action.payload;
    },
  }
})

export const ProductsReducer = productsSlice.reducer