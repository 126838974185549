import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootCartResponse} from "../../../models/cart";
import {getFromStorage} from "../../../helpers/storageFn";

interface BasketState {
  cart: RootCartResponse[];
  addedCard: Partial<RootCartResponse> | RootCartResponse;
  isLoading: boolean;
  isError: string;
}

const initialState: BasketState = {
  cart: getFromStorage('cart') || [],
  addedCard: {},
  isError: '',
  isLoading: false,
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    getCart(state){
      state.isLoading = true;
    },
    getCartSuccess(state, action: PayloadAction<RootCartResponse[]>){
      state.isLoading = false;
      state.isError = '';
      state.cart = action.payload;
    },
    getCartError(state, action: PayloadAction<string>){
      state.isLoading = false;
      state.cart = [];
      state.isError = action.payload
    },
    getAddedCard(state, action: PayloadAction<RootCartResponse>){
      state.addedCard = action.payload
    },
  }
})

export const CartReducer = cartSlice.reducer