import React from 'react';
import {Box, Button, Divider, Flex, Heading, Image, Text} from "@chakra-ui/react";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {changeFromCart} from "../../pages/Basket/BasketReducer/basketActions";

interface Props {
  onClose: () => void;
}
const ContentModalToBasket = ({onClose}: Props) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {addedCard} = useAppSelector(state => state.CartReducer)
  const addToBasket = (count: number) => {
    dispatch(changeFromCart(Number(addedCard.product?.id), count))
  }

  return (
    <Box>
      <Flex alignItems='center' >
        <Box w='100px' h='60px' mr={4}>
          <Image src={addedCard.product?.main_image} rounded='5px' width='100%' height='100%' />
        </Box>
        <Flex w='full' flexDirection='column' justifyContent='space-between' h='50px'>
          <Heading fontFamily='Roboto' fontSize='18px' fontWeight='700' color='#4D5E80' noOfLines={1}>Подвес прямой КНАУФ</Heading>
          <Flex fontFamily='Roboto' fontSize='12px' fontWeight='500' color='#394A6C' >
            <Text opacity='0.5'>Модель: </Text>
            <Text opacity='0.8'>{addedCard.product?.sub_category?.name || addedCard.product?.category.name}</Text>
          </Flex>
        </Flex>
      </Flex>
      <Divider my={3} h='1px' bg='#000000' opacity='0.1'/>
      <Flex w='full' fontFamily='Roboto' justifyContent='space-between' alignItems='center'>
        <Text fontWeight='800' fontSize='16px' color='#6B7A99'>{addedCard.product?.price} руб.</Text>
        <Flex alignItems='center'>
          <Button
            isDisabled={addedCard.quantity === 1}
            onClick={() => addToBasket(Number(addedCard.quantity) - 1)}
            fontWeight='800' px={2} py={2} color='#6B7A99'
            opacity={Number(addedCard.quantity) > 1 ? '1' : '0.2'}
            bg='#F7F8FA'
            rounded='50%'
            w='40px' h='40px'
          ><AiOutlineMinus/></Button>
          <Text fontWeight='800' fontSize='14px' color='#6B7A99' mx={3}>{addedCard.quantity}</Text>
          <Button
            onClick={() => addToBasket(Number(addedCard.quantity) + 1)}
            fontWeight='800'  color='#6B7A99'
            bg='#F7F8FA'
            rounded='50%' px={2} py={2}
            w='40px' h='40px'
          ><AiOutlinePlus/></Button>
        </Flex>
      </Flex>

      <Flex flexDirection='column' alignItems='center' mt={10}>
        <Text fontWeight='500' fontSize='16px' fontFamily='Roboto' color='#6B7A99' my={4}>Товар добавлен ​​в вашу корзину</Text>
        <Flex w='full' justifyContent={{sm: 'center', base: 'space-between'}}>
          <Button
            onClick={onClose}
            fontWeight='700'  fontSize={{md: '12px', base: '14px'}} fontFamily='Roboto' color='#40404F'
            bg='#0000001A' h={{md: '40px', base: '52px'}}
            rounded={{md: '50px', base: '500px'}} py={4}
            w='170px' mx={{sm: 4, base: 2}}
          >Вернуться</Button>
          <Button
            onClick={() => navigate('/basket')}
            fontWeight='700' fontSize={{md: '12px', base: '14px'}} fontFamily='Roboto' color='#FFFFFF'
            bg='#E66246' py={4}
            rounded={{md: '20px', base: '500px'}} h={{md: '40px', base: '52px'}}
            boxShadow='0px 10px 30px rgba(255, 102, 51, 0.3)'
            w='170px' mx={{sm: 4, base: 2}}
            variant='none'
            _active={{bg: 'rgba(230,98,70,0.73)'}}
          >Перейти в корзину</Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ContentModalToBasket;