import React from 'react';
import {Box, Heading, Input} from "@chakra-ui/react";
import {ErrorMessage} from "formik";

interface Props {
  placeholder: string;
  title: string;
  name: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: any;
  value: string;
  type: string;
}

const InputOrdering = ({title, placeholder, name, handleChange, handleBlur, value, type}: Props) => {
  return (
    <Box w={{md: '45%', base: 'full'}} mb={{base: "25px", lg: 6}}>
      <Heading px={4} pb="10px" fontFamily="Roboto" fontWeight="700" fontSize="12px"
               color="#394A6C">{title}</Heading>
      <Input
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        value={value}
        variant="none" w="full"
        h="50px" bg="#FFFFFF"
        boxShadow="0px 2px 5px rgba(54, 61, 77, 0.03)"
        border="1px solid #EBECED;"
        px={4} type={type}
        _focus={{border: "1px solid #808DA7"}}
        borderRadius="30px"
        fontFamily="Roboto" fontWeight="400" fontSize="16px"
        _placeholder={{md: {fontSize: '12px', color: '#394A6C7F'}, base: {fontSize: '14px', fontWeight: '700', color: '#394A6C7F'}}}
        color="#394A6C"
        placeholder={placeholder}
      />
      <Box px={4} fontSize='12px'  fontFamily="Roboto" fontWeight="700" color='red.500'>
        <ErrorMessage name={name}/>
      </Box>
    </Box>
  );
};

export default InputOrdering;