import React from 'react';
import {Box, Button, Divider, Flex, Image, Text, useToast} from "@chakra-ui/react";
import {MdDelete} from "react-icons/md";
import {RootCartResponse} from "../../models/cart";
import {changeFromCart, deleteFromCart} from "./BasketReducer/basketActions";
import {useAppDispatch} from "../../hooks/redux";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";

interface Props {
  element: RootCartResponse
}

const BasketCard = ({element}: Props) => {
  const dispatch = useAppDispatch()
  const toast = useToast()

  const deleteCart = () => {
    dispatch(deleteFromCart(element.product.id, toast))
  }
  const ChangeCountProduct = (count: number) => {
    dispatch(changeFromCart(element.product?.id, count, true))
  }
  return (
    <Flex justifyContent={{base: "space-evenly", md: "space-between"}} alignItems="center"
          mt="15px" w="full" h={{base: "162px", md: "100px"}}
          flexDir={{base: "column", md: "row"}} bg="#ffffff" px={{base: "10px", md: "20px"}}
          boxShadow="0px 2px 10px rgba(0, 0, 0, 0.05)" borderRadius="5px">
      <Flex alignItems={{base: "start", md: "center"}} justifyContent={{base: "space-between", md: "start"}}
            w={{base: "full", md: "50%"}}>
        <Flex>
          <Image src={element.product.main_image} alt="img" width="80px" height="60px"
                 border="1px solid rgba(54, 61, 77, 0.1)" borderRadius="5px"/>
          <Box ml="15px">
            <Text as="h1" pb={{base: "5px", sm: "10px"}} fontFamily="SF Pro Display,sans-serif" fontWeight="700"
                  fontSize="18px" color="#4D5E80" noOfLines={2}>
              {element.product.name}</Text>
            <Flex>
              <Text as="span" fontFamily="SF Pro Display,sans-serif" fontWeight="600" fontSize="12px" color="#394A6C"
                    opacity="0.5">
                Модель:&nbsp;</Text>
              <Text as="span" fontFamily="SF Pro Display,sans-serif" fontWeight="600" fontSize="12px" color="#394A6C"
                    opacity="0.8">
                {element.product.sub_category?.name || element.product.category.name}</Text>
            </Flex>
          </Box>
        </Flex>
        <Flex
          onClick={deleteCart}
          display={{base: "flex", md: "none"}}
          alignItems="center" justifyContent="end" color="#E66246"
          fontSize="25px" cursor="pointer">
          <MdDelete/></Flex>
      </Flex>
      <Divider border="0.5px solid #F4F4F4" display={{base: "block", md: "none"}}/>
      <Flex justifyContent={{base: "space-between", md: "space-around"}} alignItems="center"
            w={{base: "full", md: "50%"}}>
        <Flex alignItems="center">
          <Button
            isDisabled={element.quantity === 1}
            onClick={() =>  ChangeCountProduct(Number(element.quantity) - 1)}
            fontWeight='800' px={2} py={2} color='#6B7A99'
            opacity={Number(element.quantity) > 1 ? '1' : '0.2'}
            bg='#F7F8FA'
            rounded='50%'
            w='40px' h='40px'
          ><AiOutlineMinus/></Button>
          <Text fontWeight='500' fontSize='14px' color='#6B7A99' mx={3}>{element.quantity}</Text>
          <Button
            onClick={() => ChangeCountProduct(Number(element.quantity) + 1)}
            fontWeight='800'  color='#6B7A99'
            bg='#F7F8FA'
            rounded='50%' px={2} py={2}
            w='40px' h='40px'
          ><AiOutlinePlus/></Button>
        </Flex>
        <Text fontFamily="Roboto" fontWeight="800" fontSize="16px" color="#6B7A99">
          {element.product.price}&nbsp;руб.</Text>
        <Flex
          onClick={deleteCart}
          display={{base: "none", md: "flex"}} alignItems="center"
          _hover={{color: '#E66246'}} transition='.2s' justifyContent="center" bg="#F7F8FA"
          boxShadow=" 0px 2px 5px rgba(54, 61, 77, 0.03)" borderRadius="30px" w="40px" h="40px" color="#C3CAD9"
          fontSize="15px" ml="18px" cursor="pointer">
          <MdDelete/></Flex>
      </Flex>
    </Flex>
  );
};

export default BasketCard;