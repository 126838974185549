import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface QuestionState {
  isError: string;
  isLoading: boolean;
}

const initialState: QuestionState = {
  isError: '',
  isLoading: false,
}

export const questionsSlice = createSlice({
  name: 'form/application',
  initialState,
  reducers:{
    postQuestion(state){
      state.isLoading = true;
    },
    postQuestionSuccess(state){
      state.isLoading = false;
      state.isError = '';
    },
    postQuestionError(state, action: PayloadAction<string>){
      state.isLoading = false;
      state.isError = action.payload;
    },
  }
})

export const QuestionReducer = questionsSlice.reducer