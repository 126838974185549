import React from 'react';
import {
  Box, Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import {CloseIcon} from "@chakra-ui/icons";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: JSX.Element
}

function Placement({isOpen, onClose, children}: Props) {
  return (
    <Box display={{md: 'none', base: 'block'}}>
      <Drawer placement={'bottom'} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay/>
        <DrawerContent borderTopRadius='24px'>
          <DrawerHeader position='relative' mb={8}>
            <Box onClick={onClose} w='92px' h='6px' bg='#CFCFCF' rounded='11px' mx='auto'/>
            <Button
              onClick={onClose} bg='white'
              position='absolute' right={4} top={4} px={2}
              fontSize='1.1rem' color='#3A4F39'><CloseIcon/></Button>
          </DrawerHeader>
          <DrawerBody>
            {children}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Placement;