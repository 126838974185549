import React, {useEffect} from 'react';
import {Box, Checkbox, Flex, Switch, Text} from "@chakra-ui/react";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getCheckedValue, getDeliveryType} from "./OrderingReducer/ordering.slice";

const OrderingChecked = () => {
  const dispatch = useAppDispatch()
  const {deliveryType, paymentOnDelivery} = useAppSelector(state => state.OrderingReducer)
  const handleChange = (e: React.ChangeEvent<any>) => {
    dispatch(getDeliveryType(e.target))
  }

  const getCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(getCheckedValue(e.target.checked))
  }

  useEffect(() => {
    if (deliveryType === 'Самовывоз'){
      dispatch(getCheckedValue(false))
    }else if (deliveryType === 'Доставка'){
      dispatch(getCheckedValue(true))
    }
  }, [deliveryType])
  return (
    <Flex alignItems="start" justifyContent="space-between" mt="57px" flexDirection={{sm: 'row', base: 'column'}} pb={{sm: '5px', base: 8}}>
      <Box>
        <Switch
          name='Доставка'
          isChecked={deliveryType === "Доставка"}
          onChange={handleChange}
          size="lg"
          display="flex"
          alignItems="center"
          mb="28px"
          sx={{
            ".chakra-switch__track": {
              backgroundColor: "#FFFFFF",
              border: "2px solid #E1E2E3",
              boxShadow: '0px 2px 5px rgba(54, 61, 77, 0.03)',
            },
            ".chakra-switch__thumb": {
              backgroundColor: deliveryType === "Доставка" ? "#78C22B" : "#E4E7F0",
              width: "20px",
              height: "20px",
              margin: "2.5px 3px"
            },
          }}
        >
          <Text as="span" fontFamily="SF Pro Display,sans-serif" fontWeight="700" fontSize="12px"
                color="#7D8FB3" cursor="pointer">Доставка по городу</Text>
        </Switch>
        <Switch
          name='Самовывоз'
          isChecked={deliveryType === "Самовывоз"}
          onChange={handleChange}
          size="lg"
          display="flex"
          alignItems="center"
          mb="28px"
          sx={{
            ".chakra-switch__track": {
              backgroundColor: "#FFFFFF",
              border: "2px solid #E1E2E3",
              boxShadow: '0px 2px 5px rgba(54, 61, 77, 0.03)',
            },
            ".chakra-switch__thumb": {
              backgroundColor: deliveryType === "Самовывоз" ? "#78C22B" : "#E4E7F0",
              width: "20px",
              height: "20px",
              margin: "2.5px 3px"
            },
          }}
        >
          <Text as="span" fontFamily="SF Pro Display,sans-serif" fontWeight="700" fontSize="12px"
                color="#7D8FB3" cursor="pointer">Самовывоз</Text>
        </Switch>
      </Box>
      <Checkbox
        mx={{sm: 0, base: 2}}
        onChange={getCheckbox}
        isChecked={paymentOnDelivery}
        isDisabled={deliveryType === "Самовывоз"}
        defaultChecked={true}
        colorScheme="white"
        sx={{
          ".chakra-checkbox__control, .chakra-checkbox__control-input": {
            bg: "white",
            _checked: {
              bg: "#78C22B",
              border: "none"
            },
          },
          ".css-6x44c9": {
            fontSize: "12px"
          }
        }}
        fontFamily="SF Pro Display,sans-serif" fontWeight="700"
        color="#7D8FB3" cursor="pointer" mt="7px"
      >Оплата при доставке
      </Checkbox>
    </Flex>
  );
};

export default OrderingChecked;